import React, {useState, useEffect} from "react";
import axios from "axios";
import UserProfile from "../../userData/UserProfile";
const AdminHome = ()=>{
    const [data, setData] = useState('');
    useEffect(()=>{
      axios.get("https://api.sam.cs.mtu.edu/api/admin/getData")
      .then((response)=>{
        setData(response?.data);
      })
      .catch((error)=>{
        console.log(error);
      })
    },[])
    return (
        <div className="">
            <div className="row m-3">
                <div className="col-12 mb-3">
                    <h5 className="mb-0 mt-5 text-dark text-left">Welcome back, {UserProfile.getName()}</h5>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                    <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                        <div className="card-body text-center">
                            <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                                <h5 className="mb-0 text-dark text-center">{data?.sams?.length}</h5>
                                <p className="mb-3 text-dark text-center fw-bold">Total SAMs</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                    <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                        <div className="card-body text-center">
                            <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                                <h5 className="mb-0 text-dark text-center">{data?.advisors?.length}</h5>
                                <p className="mb-3 text-dark text-center fw-bold">Total Advisors</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                    <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                        <div className="card-body text-center">
                            <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                                <h5 className="mb-0 text-dark text-center">{data?.courses?.length}</h5>
                                <p className="mb-3 text-dark text-center fw-bold">Total Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-3 col-md-6">
                    <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                        <div className="card-body text-center">
                            <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                                <h5 className="mb-0 text-dark text-center">{data?.courseSessions?.length}</h5>
                                <p className="mb-3 text-dark text-center fw-bold">Total Sessions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AdminHome;
