import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";

const ManageCourses = () => {
    const [type, setType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [courseID, setCourseID] = useState("");
    const [courseName, setCourseName] = useState("");
    const [description, setDescription] = useState("");
    const [courses, setCourses] = useState([]);
    const years = Array.from({ length: 8 }, (_, index) => (2023 + index)); // Generate years 2024 to 2030

    // Search and Pagination States
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Number of rows per page


    const refresh = ()=>{
      axios.get("https://api.sam.cs.mtu.edu/api/admin/getCourses")
      .then((response)=>{
        setCourses(response?.data?.courses);
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    
    useEffect(()=>{
      refresh();
    },[])
    useEffect(() => {
        if (message != null) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [message]);

    // Filter courses based on the search term
    const filteredCourses = courses.filter((course) =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.courseID.toString().includes(searchTerm.toLowerCase())
    );

    // Calculate pagination
    const totalPages = Math.ceil(filteredCourses.length / rowsPerPage);
    const paginatedCourses = filteredCourses.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showAdd = () => {
        setCourseID("");
        setCourseName("");
        setDescription("");
        setType("add");
        setShowModal(true);
    };

    const addCourse = () => {
        if (courseID && courseName && description) {
            axios.post(
                `https://api.sam.cs.mtu.edu/api/admin/addCourse`,
                {
                    courseID,
                    courseName,
                    description
                },
            )
                .then(() => {
                    setError(false);
                    setMessage("Course Added Successfully!");
                    refresh();
                })
                .catch(() => {
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
        } else {
            setError(true);
            setMessage("Please fill all fields!");
        }
    };

    const showEdit = (course) => {
        setCourseID(course.courseID);
        setCourseName(course.courseName);
        setDescription(course.description);

        setType("edit");
        setShowModal(true);
    };

    const editCourse = () => {
        if (courseID && courseName && description) {
            axios.patch(
                `https://api.sam.cs.mtu.edu/api/admin/updateCourse`,
                {
                    courseID,
                    courseName,
                    description
                },
            )
                .then(() => {
                    setError(false);
                    setMessage("Course Edited Successfully!");
                    refresh();
                })
                .catch(() => {
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
        } else {
            setError(true);
            setMessage("Please fill all fields!");
        }
    };

    const showDelete = (courseID) => {
        setCourseID(courseID);
        setShowDeleteModal(true);
    };

    const deleteCourse = () => {
        axios.patch(
            `https://api.sam.cs.mtu.edu/api/admin/deleteCourse`,
            { courseID },
        )
            .then(() => {
                setError(false);
                setMessage("Course has been deleted.");
                refresh();
            })
            .catch(() => {
                setError(true);
                setMessage("Delete failed!");
            });
    };

    return (
        <>
            <div className="container col-12 col-lg-8 col-md-8">
                <h4 className="text-left text-dark mb-5 mt-5 col-md-6">
                    Course Table
                </h4>

                <button
                    className="mySmBtn mb-3"
                    onClick={() => showAdd()}
                >
                    Add Course
                </button>

                {/* Search Input */}
                <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search Courses..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {message && (
                    <p className={`alert ${!error ? "alert-success" : "alert-danger"} mt-3`}>
                        {message}
                    </p>
                )}

                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Course ID</th>
                                <th>Course Name</th>
                                <th>Course Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedCourses.map((course, idx) => (
                                <tr key={idx}>
                                    <td><code>{(currentPage - 1) * rowsPerPage + idx + 1}</code></td>
                                    <td>{course.courseID}</td>
                                    <td>{course.courseName}</td>
                                    <td>{course.description}</td>
                                    <td>
                                        <i
                                            className="bi bi-pencil-square text-success"
                                            style={{ cursor: "pointer", marginRight: "10px" }}
                                            onClick={() => showEdit(course)}
                                        ></i>
                                        <i
                                            className="bi bi-trash text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => showDelete(course.courseID)}
                                        ></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span>Page {currentPage} of {totalPages}</span>
                    <div>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i}
                                className={`btn btn-sm mx-1 ${currentPage === i + 1 ? "mySmBtn" : "mySecSmBtn"}`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this course?"
                onConfirm={() => {
                    deleteCourse();
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />

            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-dark">
                                    {type === "add" ? "Add New  Session" : "Edit Course Session"}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label>Course ID</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={courseID}
                                        onChange={(e) => setCourseID(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Course Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={courseName}
                                        onChange={(e) => setCourseName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Description</label>
                                    <textarea
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        type === "edit" ? editCourse() : addCourse();
                                        setShowModal(false);
                                    }}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ManageCourses;
