import React, {useState, useRef} from "react";
import Cropper from "react-easy-crop";
import '../../Styles/sam.css';
function PreviewPfp({photoUrl, uploadAction, cancelAction}){
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const area=useRef(null);
  const cropComplete = (croppedArea, croppedAreaPixels)=>{
    area.current=croppedAreaPixels;
    console.log(croppedArea, croppedAreaPixels);
  }
  const cropPhoto = ()=>{
    const img = new Image();
    img.src = photoUrl;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = area.current.width;
    canvas.height=area.current.height;
    ctx.drawImage(
      img,
      area.current.x,
      area.current.y,
      area.current.width,
      area.current.height,
      0,0, area.current.width, area.current.height
    );
    return new Promise((resolve)=>{
      canvas.toBlob((blob)=>{
        const file = new File([blob], 'croppedPhoto.jpeg', {type: 'image/jpeg'});
        resolve(file);
      }, 'image/jpeg');
    });
  }
  const submitPhoto = ()=>{
    let p = cropPhoto();
    p.then((blob)=>{
      uploadAction(blob);
    });
  }
  return(
    <div className="position-absolute d-flex flex-column justify-content-center align-items-center"
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100vh',
        zIndex: 2000,
        backgroundColor: "rgba(0,0,0,0.5)"
      }}
    >
      <div className="container position-relative d-flex justify-content-center align-items-center"
      style={{
      aspectRatio: '1/1',
      minWidth:'15%',
      maxWidth: '25%',
      zIndex: 2000
      }}
    >
          <Cropper
          image={photoUrl}
          zoom={zoom}
          crop={crop}
          aspect={3/3}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          onZoomChange={setZoom}
          cropShape={'round'}
          zoomSpeed={.35}
          />
      </div>
      <div className="d-flex justify-content-center align-items-center gap-5">
        <button className="myBtn mt-2" onClick={submitPhoto}>
        Submit
        </button>
        <button className="myBtn mt-2" onClick={cancelAction}>
        Cancel 
        </button>
      </div> 
    </div>
  );
}
export default PreviewPfp;
