import React from 'react';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Io from 'react-icons/io';
import { PiStudentBold } from 'react-icons/pi';

export const SidebarData = [
    {
        title: 'Dashboard',
        path: '',
        icon: <Ai.AiFillHome />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Students',
        path: 'students',
        icon: <PiStudentBold />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Sams',
        path: 'sams',
        icon: <Io.IoIosPeople />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Advisors',
        path: 'advisors',
        icon: <Io.IoIosPeople />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Semesters',
        path: 'semesters',
        icon: <Io.IoMdCalendar />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Course Sessions',
        path: 'sessions',
        icon: <Io.IoMdFiling />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Courses',
        path: 'courses',
        icon: <Io.IoMdFiling />,
        cName: 'nav-text navT'
    },
    {
        title: 'Upload Roster',
        path: 'roster',
        icon: <Fa.FaClipboardList/>,
        cName: 'nav-text navT'
    }
];
