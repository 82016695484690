/**
 * Joshua Alele-Beals
 * joshbeals22@gmail.com
 * github.com/joshBeals
 */
import React, { createContext, useContext, useEffect, useState } from 'react';
import {useParams} from 'react-router';
const AppStateContext = createContext();

export const AppStateProvider = ({ children }) => {

  // const [convictions, setConvictions] = useState(() => {
  //   const savedConviction = localStorage.getItem('ConvictionContext');
  //   return savedConviction ? JSON.parse(savedConviction) : [];
  // });
  const params = useParams();
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedScheduleID, setSelectedScheduleID] = useState(0);

  useEffect(() => { 
    if(params?.courseID!==undefined && courseList.includes(params?.courseID)){
      setSelectedCourse(params?.courseID);
    }
  }, []);

  // Value to be passed to consuming components
  const value = {
    selectedCourse,
    setSelectedCourse,
    selectedScheduleID,
    setSelectedScheduleID,
    selectedSession,
    setSelectedSession
  };

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};

export const useAppState = () => useContext(AppStateContext);
