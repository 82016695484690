import React, { useState, useEffect } from "react";
import NewProblem from "../SAMandAdvisorComponents/PracticeQuestionsComponents/NewProblem";
import ViewProblem from "../SAMandAdvisorComponents/PracticeQuestionsComponents/ViewProblem";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useAppState} from "../../store/AppStateContext";

function PracticeProblemsSam() {
  const [selectedProblem, setSelectedProblem] = useState(-1);
  const [problemList, setProblemList] = useState(null);
  const [addMessage, setAddMessage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryMap, setCategoryMap] = useState({});
  const [deletion, setDeletion] = useState(false);
  const {selectedCourse, setSelectedCourse} = useAppState()
  const whom = "sam";
  const checkSelection = (event) => {
    setSelectedProblem(parseInt(event.target.value));
  };

  useEffect(() => {
    if (deletion) {
      setDeletion(false);
    } else {
      setAddMessage(null);
    }
  }, [selectedProblem]);

  useEffect(() => {
    setSelectedProblem(-1);
    setCategories([]);
    if(selectedCourse!==0 && selectedCourse!==undefined) fetchProblems();
  }, [selectedCourse]);

  useEffect(() => {
    if (addMessage !== null) {
      const timer = setTimeout(() => {
        setAddMessage(null);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [addMessage]);

  const fetchProblems = () => {
    axios
      .get(`https://api.sam.cs.mtu.edu/api/course/practiceproblems/${selectedCourse}`)
      .then((response) => {
        const problemMap = new Map();
        const categoryMapTemp = {};
        const categorySet = new Set();

        response.data.forEach((entry) => {
          categorySet.add(entry.topic);
          problemMap.set(parseInt(entry.problemID), {
            name: entry.problemName,
            topic: entry.topic,
            description: entry.description,
            hint: entry.hint,
            comments: entry.comments,
          });

          if (categoryMapTemp[entry.topic]) {
            categoryMapTemp[entry.topic].push({
              id: entry.problemID,
              name: entry.problemName,
            });
          } else {
            categoryMapTemp[entry.topic] = [
              {
                id: entry.problemID,
                name: entry.problemName,
              },
            ];
          }
        });

        setCategories(Array.from(categorySet));
        setCategoryMap(categoryMapTemp);
        setProblemList(problemMap);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllProblems = () => {
    return categories.map((category) => (
      <optgroup key={`problemOptgroup-${category}-${selectedCourse}`} label={category}>
        {getAllProblemsInCategory(category)}
      </optgroup>
    ));
  };

  const getAllProblemsInCategory = (category) => {
    return categoryMap[category]?.map((problem) => (
      <option key={`problemIn${category}-${problem.id}`} value={problem.id}>
        {problem.name}
      </option>
    ));
  };

  const getViewProblem = () => {
    const entry = problemList.get(selectedProblem);
    const info = [entry.topic, entry.name, entry.description, entry.hint, entry.comments];
    return (
      <ViewProblem
        key={`viewProblem=${selectedProblem}-${selectedCourse}`}
        updateFunction={updateProblem}
        deleteFunction={deleteProblem}
        problemInfo={info}
        categories={categories}
      />
    );
  };

  const addProblem = (problemInfo) => {
    setAddMessage("Processing...");
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/${whom}/addproblem/${selectedCourse}`,
        {
          name: problemInfo[1],
          topic: problemInfo[4],
          description: problemInfo[0],
          comments: problemInfo[3],
          hint: problemInfo[2],
        },
        { withCredentials: true }
      )
      .then((response) => {
        setAddMessage("Problem Added!");
        fetchProblems();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateProblem = (problemInfo) => {
    setAddMessage("Updating...");
    axios
      .patch(
        `https://api.sam.cs.mtu.edu/api/${whom}/updateproblem/${selectedProblem}`,
        {
          name: problemInfo[1],
          topic: problemInfo[4],
          description: problemInfo[0],
          comments: problemInfo[3],
          hint: problemInfo[2],
        },
        { withCredentials: true }
      )
      .then((response) => {
        setAddMessage("Problem Updated!");
        fetchProblems();
      })
      .catch((error) => console.log(error));
  };

  const deleteProblem = () => {
    setAddMessage("Processing...");
    axios
      .delete(
        `https://api.sam.cs.mtu.edu/api/${whom}/deleteproblem/${selectedProblem}`,
        { withCredentials: true }
      )
      .then((response) => {
        setDeletion(true);
        setSelectedProblem(-1);
        fetchProblems();
        setAddMessage("Problem Deleted!");
      })
      .catch((error) => {
        setAddMessage("Deletion Failed");
      });
  };

  const checkProblemSelection = (event) => {
    setSelectedProblem(parseInt(event.target.value));
  };

  return (
    <div className="dash-content">
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 card p-4">
            <h4 className="text-center mb-5 text-black">Practice Problems</h4>
            {addMessage && (
              <p className="alert alert-info mt-3">{addMessage}</p>
            )}
            <div className="form-group">
              <select
                className="form-select"
                onChange={checkProblemSelection}
                value={selectedProblem}
              >
                {problemList !== null ? (
                  <>
                    <option value={-1}>Please make a selection</option>
                    <option value={-2}>Create a new problem</option>
                    {getAllProblems()}
                  </>
                ) : (
                  <option value={-1}>Loading...</option>
                )}
              </select>
            </div>
            <div className="mt-4">
              {selectedProblem !== -1 ? (
                selectedProblem !== -2 ? (
                  getViewProblem()
                ) : (
                  <NewProblem
                    categories={categories}
                    submitFunction={addProblem}
                    message={addMessage}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PracticeProblemsSam;
