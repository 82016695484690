import React, {useEffect, useRef, useState} from "react";

function SessionUpdateModal({ title, sessionRef, onConfirm, onCancel }) {

  const [warningMessage, setWarningMessage] = useState(null);
  const checkFields = ()=>{
    const startTime = sessionRef?.current?.sessionTime;
    const endTime = sessionRef?.current?.endTime;
    const sessionLocation = sessionRef?.current?.sessionLocation;
    const weekday = sessionRef?.current?.weekday;
    if(!startTime || !endTime || !sessionLocation || !weekday || sessionLocation.length<1){
      setWarningMessage("All fields must be completed!");
    }else if(startTime>endTime){
      setWarningMessage("Start time must be before end time");
    }else{
      onConfirm();
    }
  }
  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark">{title || "Confirm Action"}</h5>
          </div>
          <div className="modal-body">
            <div>
              <div className="form-group row mb-1">
                <span className="col-form-label col-sm-3"><strong>Weekday: </strong></span>
                <div className="col-sm-9">
                  <select onChange={(e)=>sessionRef.current={...sessionRef.current, weekday: e.target.value}} className="form-control" defaultValue={sessionRef?.current?.weekday}>
                    <option value={"Monday"}>Monday</option>
                    <option value={"Tuesday"}>Tuesday</option>
                    <option value={"Wednesday"}>Wednesday</option>
                    <option value={"Thursday"}>Thursday</option>
                    <option value={"Friday"}>Friday</option>
                    <option value={"Saturday"}>Saturday</option>
                    <option value={"Sunday"}>Sunday</option>
                  </select>
                </div>
              </div>
              <div className="form-group row mb-1">
                <span className="col-form-label col-sm-3"><strong>Start Time: </strong></span>
                <div className="col-sm-9">
                  <input type="time" onChange={(e)=>sessionRef.current={...sessionRef.current, sessionTime: e.target.value}}className="form-control" defaultValue={sessionRef?.current?.sessionTime?.slice(0,5)}/>
                </div>
              </div>
              <div className="form-group row mb-1">
                <span className="col-form-label col-sm-3"><strong>End Time: </strong></span>
                <div className="col-sm-9">
                  <input type="time" onChange={(e)=>sessionRef.current={...sessionRef.current, endTime: e.target.value}} className="form-control" defaultValue={sessionRef?.current?.endTime?.slice(0,5)}/>
                </div>
              </div>
              <div className="form-group row">
                <span className="col-form-label col-sm-3"><strong>Location:</strong></span>
                <div className="col-sm-9">
                  <input type="text" onChange={(e)=>sessionRef.current={...sessionRef.current, sessionLocation: e.target.value}} className="form-control" defaultValue={sessionRef?.current?.sessionLocation || ""} placeholder={"Required"}/>
                </div>
              </div>
            </div>
            {warningMessage?
              <div className="alert alert-warning mt-2" role="alert">{warningMessage}</div>
              :null
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={checkFields}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionUpdateModal;
