import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import WeeklySessions from "./WeeklySessions.jsx";
import CancelSession from "./CancelSession.jsx";
import NonWeeklySession from "./NonWeeklySession.jsx";
import { useAppState } from "../../store/AppStateContext";
const SessionUpdaters = () => {
  const [samSchedule, setSamSchedule] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const [specialSchInfo, setSpecialSchInfo] = useState(null);
  const { selectedCourse, setSelectedCourse } = useAppState();
  const [logMessage, setLogMessage] = useState(null);
  const timeoutRef = useRef();
  const statusRef = useRef();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setLogMessage(null);
    }, 5000);
  }, [logMessage]);
  const getScheduleData = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/getscheduleinfo")
      .then((response) => {
        let sessionVals = Object.values(response.data[0]); //This only includes non-reoccuring sessions
        let weeklyVals = Object.values(response.data[1]); //This only includes reoccuring sessions
        let coursesTemp = Object.values(response.data[2]); //This is a list of all courses the requestor is a SAM for
        let allSessions = Object.values(response.data[3]); //This includes non-reoccuring and reoccuring sessions
        let courseVals = [];
        let setArr = [sessionVals, weeklyVals, courseVals, allSessions];
        setSamSchedule(setArr);
      })
      .catch((error) => console.log(error));

    axios
      .get(`https://api.sam.cs.mtu.edu/api/sam/fullSchedule/${selectedCourse}`)
      .then((response) => {
        setScheduleInfo(response.data.response[0]);
      });
    axios
      .get(`https://api.sam.cs.mtu.edu/api/sam/specialSessions`)
      .then((response) => {
        setSpecialSchInfo(response?.data);
      });
  };

  useEffect(() => {
    getScheduleData();
  }, []);

  const addWeeklySession = (info) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/addWeeklySession", {
        weekday: info[0],
        time: info[1],
        location: info[2],
        courseID: selectedCourse,
        endtime: info[3],
      })
      .then(() => {
        setLogMessage("Session Added!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
      });
  };

  //Helper function to update a weekly sessionList
  const updateWeeklySession = (info) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .patch("https://api.sam.cs.mtu.edu/api/sam/updateWeeklySession", {
        weekday: info[0],
        weeklySessionID: info[3],
        time: info[1],
        location: info[2],
        endtime: info[4],
      })
      .then(() => {
        setLogMessage("Session Updated!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
      });
  };

  //Helper function to delete a weekly sessionList
  const deleteWeeklySession = (id) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .delete(`https://api.sam.cs.mtu.edu/api/sam/deleteWeeklySession/${id}`)
      .then(() => {
        setLogMessage("Session Deleted!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  const cancelSession = (sessionID) => {
    return axios.delete(
      `https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`
    );
    //Cancel logic goes here
  };

  const joinSession = (sessionID) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post(`https://api.sam.cs.mtu.edu/api/sam/joinWeeklySession/${sessionID}`)
      .then(() => {
        setLogMessage("Session Joined!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  const leaveSession = (sessionID) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/sam/leaveWeeklySession/${sessionID}`
      )
      .then(() => {
        setLogMessage("Session Left!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  const addSpecialSession = (info) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/addSpecialSession", {
        sessionDate: info[0],
        sessionTime: info[1],
        endTime: info[2],
        courseID: selectedCourse,
        sessionLocation: info[3],
        notes: info[4],
      })
      .then(() => {
        setLogMessage("Session Added!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
      });
  };

  //Helper function to update a weekly sessionList
  const updateSpecialSession = (info) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .patch("https://api.sam.cs.mtu.edu/api/sam/updateSpecialSession", {
        sessionDate: info[0],
        sessionTime: info[1],
        endTime: info[2],
        sessionLocation: info[3],
        notes: info[4],
        sessionID: info[5],
      })
      .then(() => {
        setLogMessage("Session Updated!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  //Helper function to delete a weekly sessionList
  const deleteSpecialSession = (id) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .delete(`https://api.sam.cs.mtu.edu/api/sam/deleteSpecialSession/${id}`)
      .then(() => {
        setLogMessage("Session Deleted");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  const joinSpecialSession = (sessionID) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/sam/joinSpecialSession/${sessionID}`
      )
      .then(() => {
        setLogMessage("Session Joined!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };

  const leaveSpecialSession = (sessionID) => {
    setLogMessage("Processing");
    statusRef.current = "alert alert-secondary";
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/sam/leaveSpecialSession/${sessionID}`
      )
      .then(() => {
        setLogMessage("Session Left!");
        statusRef.current = "alert alert-success";
        getScheduleData();
      })
      .catch((error) => {
        setLogMessage("Request Failed!");
        statusRef.current = "alert alert-danger";
        console.log(error);
      });
  };
  return (
    <div className="dash-content">
      <div className="samDashSection scheduleSection px-3 mb-4">
        <h4 className="text-center text-dark mt-5">Manage SAM Sessions</h4>
        {scheduleInfo !== null && specialSchInfo !== null ? (
          <>
            <WeeklySessions
              scheduleInfo={[samSchedule[2], samSchedule[1]]}
              weeklySessionAdder={addWeeklySession}
              weeklySessionUpdater={updateWeeklySession}
              weeklySessionDeleter={deleteWeeklySession}
              weeklySessionJoiner={joinSession}
              weeklySessionLeaver={leaveSession}
              currentCourse={selectedCourse}
              setCurrentCourse={setSelectedCourse}
              tableData={scheduleInfo}
            />
            <CancelSession
              sessionList={samSchedule[3]}
              cancelFunction={cancelSession}
            />
            <NonWeeklySession
              scheduleInfo={specialSchInfo}
              weeklySessionAdder={addSpecialSession}
              weeklySessionUpdater={updateSpecialSession}
              weeklySessionDeleter={deleteSpecialSession}
              weeklySessionJoiner={joinSpecialSession}
              weeklySessionLeaver={leaveSpecialSession}
              currentCourse={selectedCourse}
              setCurrentCourse={setSelectedCourse}
              tableData={specialSchInfo}
            />
          </>
        ) : null}
        {logMessage !== null ? (
          <div className={statusRef.current}>{logMessage}</div>
        ) : null}
      </div>
    </div>
  );
};

export default SessionUpdaters;
