import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import RosterModal from "./RosterModal.jsx";
function UploadRoster() {
  const [message, setMessage] = useState(null);
  const [displayButton, setDisplayButton] = useState(false);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [courseSessions, setCourseSessions] = useState([]);
  const [selectedScheduleID, setSelectedScheduleID] = useState(null);
  const oldTimeout = useRef(null);

  useEffect(() => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/admin/getCourseSessions")
      .then((response) => {
        console.log(response?.data?.courses);
        setCourseSessions(response?.data?.courses);
        setSelectedScheduleID(response?.data?.courses[0]?.scheduleID);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (message !== null && message !== undefined) {
      clearTimeout(oldTimeout.current);
      oldTimeout.current = setTimeout(() => {
        setMessage(null);
      }, 30000);
    }
  }, [message]);

  const checkUpload = (event) => {
    if (event.target.files[0].type !== "text/csv") {
      setMessage("Please only upload a .csv file");
      event.target.value = null;
    } else {
      setMessage(null);
      setDisplayButton(true);
      setFile(event.target);
    }
  };
  const uploadFile = () => {
    if (file.files.length === 0) {
      setMessage("Please upload a .csv file");
    } else if (file.files[0].type !== "text/csv") {
      setMessage("Please only upload a .csv file");
    } else {
      let form = new FormData();
      form.append("file", file.files[0]);
      setShowModal(false);
      setMessage("Processing... Please wait");
      axios
        .post(
          `https://api.sam.cs.mtu.edu/api/admin/uploadroster/${selectedScheduleID}`,
          form,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          setMessage("Upload Complete!");
        })
        .catch((error) => {
          setMessage("Upload Failed!");
        });
    }
  };
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-8 d-flex flex-column justify-content center align-items-center">
          <h4 className="text-center text-dark mb-5">Upload Roster</h4>
          {showModal ? (
            <RosterModal
              show={true}
              title={"Upload Roster"}
              message={""}
              onConfirm={uploadFile}
              onCancel={() => setShowModal(false)}
              sessions={courseSessions}
              setSchID={setSelectedScheduleID}
              setFile={setFile}
            />
          ) : null}
          <button
            className="myBtn w-50 mt-2"
            onClick={() => setShowModal(true)}
          >
            Upload
          </button>
          {message ? (
            <p className="alert alert-info mt-3 text-center">{message}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default UploadRoster;
