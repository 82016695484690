import React from 'react';

const Loader = () => {
    return (
        <div className="spinner-border text-primary">
            <div className="sr-only">Loading...</div>
        </div>
    )
}

export default Loader;