import React, { useState, useEffect, useRef } from "react";
import NonWeeklyModal from "./NonWeeklyModal.jsx";
import "../../Styles/sam.css";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import check from "../../assets/check.svg";
import cancel from "../../assets/cancel.svg";
import ConfirmationModal from "./ConfirmationModal";
function NonWeeklySessions({
  scheduleInfo,
  weeklySessionAdder,
  weeklySessionUpdater,
  weeklySessionDeleter,
  weeklySessionJoiner,
  weeklySessionLeaver,
  currentCourse,
}) {
  const [sessionMap, setSessionMap] = useState(new Map());
  const [currentSession, setCurrentSession] = useState(0);
  const [sessionList, setSessionList] = useState(null);
  const [sessionMessage, setSessionMessage] = useState(null);
  const messageTimeout = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const dataRef = useRef();
  useEffect(() => {
    if (sessionMessage !== null) {
      clearTimeout(messageTimeout.current);
      messageTimeout.current = setTimeout(() => {
        setSessionMessage(null);
      }, 10000);
    }
  }, [sessionMessage]);
  //Resetting session message on course change
  useEffect(() => {
    setSessionMessage(null);
  }, [currentCourse]);

  //Get sessions into a map for easy data access
  const getSessions = () => {
    let sessionMapTemp = new Map();
    scheduleInfo?.forEach((session) => {
      console.log("logging session");
      console.log(session);
      if (!sessionMapTemp.has(session.courseID)) {
        sessionMapTemp.set(session.courseID, [
          {
            sessionID: session?.sessionID,
            sessionDate: session?.sessionDate,
            sessionTime: session?.sessionTime,
            endTime: session?.endTime,
            sessionLocation: session?.sessionLocation,
            notes: session?.notes,
            status: session?.status,
          },
        ]);
      } else {
        let oldEntry = sessionMapTemp.get(session.courseID);
        oldEntry.push({
          sessionID: session?.sessionID,
          sessionDate: session?.sessionDate,
          sessionTime: session?.sessionTime,
          endTime: session?.endTime,
          sessionLocation: session?.sessionLocation,
          notes: session?.notes,
          status: session?.status,
        });
        sessionMapTemp.set(session?.courseID, oldEntry);
      }
    });
    setSessionMap(sessionMapTemp);
  };

  //Helper function to get time in readable format
  const getFormattedTime = (time) => {
    let [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();

    const suffix = (day) => {
      if (day % 10 === 1 && day !== 11) return "st";
      if (day % 10 === 2 && day !== 12) return "nd";
      if (day % 10 === 3 && day !== 13) return "rd";
      return "th";
    };

    return `${month} ${day}${suffix(day)}`;
  };

  //Updates the course entries whenever there is new schedule info passed in
  useEffect(() => {
    getSessions();
    setCurrentSession(0);
  }, [scheduleInfo]);
  useEffect(() => {
    getWeeklySessionItems();
  }, [sessionMap]);

  //Helper function to reset the session dropdown properly
  const resetSessionSelection = (message) => {
    setCurrentSession(0);
    setSessionMessage(message);
  };

  const confirmDeleteSession = (sessionID) => {
    setSessionToDelete(sessionID);
    setShowDeleteModal(true);
  };

  const deleteSpecialSession = () => {
    if (sessionToDelete !== null) {
      weeklySessionDeleter(sessionToDelete);
      resetSessionSelection("Session Deleted!");
      setShowDeleteModal(false);
      setSessionToDelete(null);
    }
  };

  //Helper function to get all of the sessions for the dropdown box
  const getSessionEntries = () => {
    let returnSet = [];
    if (sessionMap.get(parseInt(currentCourse)) !== undefined) {
      let sessionList = sessionMap.get(parseInt(currentCourse));
      sessionList.forEach((session) => {
        let currentID = session.sessionID;
        returnSet.push(
          <li
            sessionid={session.sessionID}
            key={session.sessionID}
            className="weeklyCourseSessionOption"
          >
            <p className={`sessionImmutableInfo `} style={{ margin: "0px" }}>
              {" "}
              {`${getFormattedDate(session?.sessionDate)} at ${getFormattedTime(session.sessionTime.slice(0, 5))} to ${getFormattedTime(session.endTime)} in ${session.sessionLocation}`}
            </p>
            <span className="weeklySessionIconWrapper">
              {session.status === 1 ? (
                <button
                  value={session.sessionID}
                  onClick={(e) => weeklySessionLeaver(e.target.value)}
                  className="btn btn-sm btn-warning"
                >
                  Leave Session
                </button>
              ) : (
                <button
                  value={session.sessionID}
                  onClick={(e) => weeklySessionJoiner(e.target.value)}
                  className="btn btn-sm btn-warning"
                >
                  Join Session
                </button>
              )}
              <i
                className="bi bi-pencil-square text-success"
                style={{
                  cursor: "pointer",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
                onClick={() => {
                  setCurrentSession(
                    parseInt(event.target.getAttribute("value"))
                  );
                }}
                value={currentID}
              ></i>
              <i
                className="bi bi-trash text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => confirmDeleteSession(currentID)}
              ></i>
            </span>
          </li>
        );
      });
    }
    returnSet.push(
      <li key="add-session" className="list-group-item text-left">
        <button
          className="btn btn-outline-primary"
          onClick={() => setCurrentSession(1)}
        >
          + Add New Session
        </button>
      </li>
    );

    return returnSet;
  };

  const cancelOrDelete = (event) => {
    let value = parseInt(event.target.getAttribute("value"));
    if (value === currentSession) {
      setCurrentSession(null);
    } else {
      weeklySessionDeleter(value);
      resetSessionSelection("Session Deleted!");
    }
  };

  //Generating list of sessions to select to update
  const getWeeklySessionItems = () => {
    setSessionList(
      <ul className="weeklySessionOptions">{getSessionEntries()}</ul>
    );
  };

  const updateSession = () => {
    let info = [
      dataRef?.current?.sessionDate,
      dataRef?.current?.sessionTime,
      dataRef?.current?.endTime,
      dataRef?.current?.sessionLocation,
      dataRef?.current?.notes
    ];
    if (currentSession === 1) {
      info.push(currentCourse);
      weeklySessionAdder(info);
    } else {
      console.log("In here 2");
      info.push(currentSession);
      weeklySessionUpdater(info);
    }
  };
  const getEditModal = () => {
    if (currentSession !== 1) {
      const session = scheduleInfo.find(
        (entry) => entry.sessionID=== currentSession
      );
      dataRef.current = session;
    } else {
      dataRef.current = {
        courseID: currentCourse,
        sessionTime: null,
        endTime: null,
        sessionDate: null,
        sessionLocation: null,
        notes: null,
      };
    }

    return (
      <NonWeeklyModal
        title={currentSession !== 1 ? "Update Session" : "Add Session"}
        sessionRef={dataRef}
        onConfirm={updateSession}
        onCancel={cancelUpdate}
      />
    );
  };

  const cancelUpdate = () => {
    dataRef.current = null;
    setCurrentSession(0);
  };
  const getSessionList = () => {
    return sessionList;
  };

  return (
    <div className="col-12 col-lg-6 col-md-8">
      <h5 className="text-left text-dark mb-3">Extra Sessions</h5>
      <div className="card">
        {sessionList !== null ? getSessionList() : null}
        {currentSession !== 0 ? getEditModal() : null}
      </div>
      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this session?"
        onConfirm={deleteSpecialSession}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default NonWeeklySessions;
