import React, {useState, useEffect} from "react";
import axios from "axios";
const SamSessions = ({ username, courses, courseSessions, setShowModal, setError, setMessage, refresh }) => {
    
    const [scheduleID, setScheduleID] = useState("");

    const addSchedule = () => {
        if (username && scheduleID) {
            axios.post(
                "https://api.sam.cs.mtu.edu/api/admin/addSamToCourse",
                {
                    username,
                    scheduleID
                },
                { withCredentials: true }
            )
            .then(() => {
                setError(false);
                setMessage("SAM Assigned to Course Schedule Successfully!");
                refresh();
                setShowModal(false);
            })
            .catch(() => {
                setError(true);
                setMessage("Something went wrong! Please try again!");
                setShowModal(false);
            });
        } else {
            setError(true);
            setMessage("Please select session!");
            setShowModal(false);
        }
    }

    const removeSchedule = (scheduleID) => {
        axios.post(
            "https://api.sam.cs.mtu.edu/api/admin/removeSamFromCourse",
            {
                username,
                scheduleID
            },
            { withCredentials: true }
        )
        .then(() => {
            setError(false);
            setMessage("SAM Removed from Course Schedule Successfully!");
            refresh();
            setShowModal(false);
        })
        .catch(() => {
            setError(true);
            setMessage("Something went wrong! Please try again!");
            setShowModal(false);
        });
    }

    return (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-dark">
                            Assign {username} to Session
                        </h5>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <label>Select Session</label>
                            <select
                                className="form-control"
                                value={scheduleID}
                                onChange={(e) => setScheduleID(e.target.value)}
                            >
                                {courseSessions.map((session) => (
                                    <option key={session?.scheduleID} value={session?.scheduleID}>
                                        {session?.courseID}-{session?.semester}-{session?.year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="mySmBtn mb-5" onClick={() => addSchedule()}>
                            Submit
                        </button>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Assigned Schedules</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {courses.map((data, idx) => (
                                        <tr key={idx}>
                                            <td><code>{++idx}</code></td>
                                            <td>{data?.courseID}-{data?.semester}-{data?.year}</td>
                                            <td>
                                                <i
                                                    className="bi bi-trash text-danger"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => removeSchedule(data?.scheduleID)}
                                                ></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SamSessions;
