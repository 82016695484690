import React, { useEffect } from 'react';
import BannerCarousel from '../components/Carousel';
import icon1 from '../assets/course1.png';
import icon2 from '../assets/course2.png';
import icon3 from '../assets/course3.png';
import icon4 from '../assets/course4.png';
import TabsArea from '../components/TabsArea';
import { Link } from 'react-router';

const Homepage = () => {

  return (
    <>
        <BannerCarousel />
        <div className="main-content">
            <section className="pt-80 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 align-self-lg-center">
                            <div className="about-caption text-center">
                                <h6 className="title">Courses Offering SAM Support</h6>
                                <p>Each course will have designated SAMs who provide support tailored
                                to the course material, <br /> helping students succeed and excel in their
                                studies.</p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="row">
                                  <div className="col-lg-3 col-md-6">
                                    <Link to={`courses/2311`} draggable={false}>
                                    <div className="services-7">
                                        <span className="icon-block th-cl"><img src={icon2} alt="Digital Classroom" /></span>
                                        <h4 className="title">CS2311: <br /> Discrete Structures</h4>
                                        <p>Presents fundamental concepts in discrete structures.</p>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <Link to={`courses/2321`} draggable={false}>
                                    <div className="services-7">
                                        <span className="icon-block th-cl"><img src={icon1} alt="Natural Compass" /></span>
                                        <h4 className="title">CS2321: <br /> Data Structures</h4>
                                        <p>Presents fundamental concepts in data structures.</p>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <Link to={`courses/3331`} draggable={false}>
                                    <div className="services-7">
                                        <span className="icon-block th-cl"><img src={icon4} alt="No Extra Charge" /></span>
                                        <h4 className="title">CS3331: <br /> Concurrent Computing</h4>
                                        <p>Concepts and techniques in concurrent computing.</p>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <Link to={`courses/3411`} draggable={false}>
                                    <div className="services-7">
                                        <span className="icon-block th-cl"><img src={icon3} alt="Events & Meetup" /></span>
                                        <h4 className="title">CS3411: <br /> Systems Programming</h4>
                                        <p>Development of robust programs for system software developers.</p>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="th-bg courses-bg pt-80 pb-100" data-overlay="04">
                <div className="container">
                    <div className="section-title-2 text-center">
                        <h6 className="title" style={{ color: 'black', fontSize: '40px' }}>SAM Program Information</h6>
                    </div>
                    <TabsArea />
                </div>
            </section>
        </div>
    </>
  );
};

export default Homepage;
