import React from 'react';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Io from 'react-icons/io';
import { PiStudentBold } from 'react-icons/pi';

export const SidebarData = [
    {
        title: 'Dashboard',
        path: 'home',
        icon: <Ai.AiFillHome />,
        cName: 'nav-text navT'
    },
    {
        title: 'Upload Roster',
        path: 'roster',
        icon: <Fa.FaClipboardList/>,
        cName: 'nav-text navT'
    }
];
