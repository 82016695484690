import axios from "axios";
const UserProfile = (function () {
  let fullName = localStorage.getItem("name") || "";
  let roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("roles"))
    : {
        Student: false,
        SAM: false,
        Advisor: false,
        Admin: false,
      };
  let courses = localStorage.getItem("courses")
    ? JSON.parse(localStorage.getItem("courses"))
    :{
      2311: false,
      2321: false,
      3331: false,
      3411: false,
    };
  const getName = () => {
    return fullName;
  };

  const getRoles = () => {
    return roles;
  };
  const getCourses = ()=>{
    return courses;
  }
  const setRoles = (newRoles) => {
    roles = { ...roles, ...newRoles };
    localStorage.setItem("roles", JSON.stringify(roles));
  };
  const setCourses = (newCourses)=>{
    courses = {...courses, ...newCourses};
    localStorage.setItem("courses", JSON.stringify(courses));
  }
  const registerUser = (name, userRoles = {}, userCourses = {}) => {
    localStorage.clear();
    setName(name);
    setRoles(userRoles);
    setCourses(userCourses);
  };

  const setName = (name) => {
    fullName = name;
    localStorage.setItem("name", name);
  };

  const logOut = () => {
    localStorage.clear();
    fullName = "";
    roles = {
      Student: false,
      SAM: false,
      Advisor: false,
      Admin: false,
    };
    courses = {
      2311: false,
      2321: false,
      3331: false,
      3411: false,
    };
  };

  const isLoggedIn = async () => {
    try {
      const response = await axios.get(
        "https://api.sam.cs.mtu.edu/api/auth/check-session",
        { withCredentials: true },
      );
      if (response.status === 200) {
        return true;
      } else {
        logOut();
        return false;
      }
    } catch (error) {
      logOut();
      return false;
    }
  };

  return {
    getName,
    getRoles,
    getCourses,
    setName,
    setRoles,
    setCourses,
    registerUser,
    isLoggedIn,
    logOut,
  };
})();

export default UserProfile;
