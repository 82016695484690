import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import UploadMaterial from '../fileSharingComponents/UploadMaterial';
import DownloadMaterial from '../fileSharingComponents/DownloadMaterial';
import {useAppState} from '../../store/AppStateContext';
const classNames = {
    2311: "Discrete Structures", 
    2321: "Data Structures",
    3331: "Concurrent Computing", 
    3411: "Systems Programming"
};

function FileSharing() {
    const [uploadMessage, setUploadMessage] = useState(null);
    const [materialList, setMaterialList] = useState(null);
    const {selectedCourse, setSelectedCourse} = useAppState(); 
    useEffect(() => {
        fetchFiles();
    }, [selectedCourse]);
    const messageRef = useRef();
    const fetchFiles = () => {
        axios.get(`https://api.sam.cs.mtu.edu/api/sam/materials/view/${selectedCourse}`, {
            withCredentials: true
        }).then((response) => {
            let matMap = new Map();
            response.data.returnArr.forEach(entry => {
                matMap.set(entry.materialID, {
                    id: entry.materialID,
                    fileLocation: entry.fileLocation,
                    name: entry.materialName,
                    postTime: entry.uploadTime,
                    comments: entry.comments
                });
            });
            setMaterialList(matMap);
        }).catch(error => console.log(error));
    };

    const uploadPost = (form) => {
        setUploadMessage("Processing");
        messageRef.current = "alert alert-secondary";
        axios.post(`https://api.sam.cs.mtu.edu/api/sam/materials/upload/${selectedCourse}`, form, {
            withCredentials: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            fetchFiles();
            setUploadMessage("Upload Successful");
            messageRef.current = "alert alert-success";
        }).catch(error => {
            setUploadMessage("Upload Failed!");
            messageRef.current = "alert alert-danger";
            console.log(error);
        });
    };

    /*
        To delete a post use the /api/sam/deletematerials/:id endpoint with a delete request and with the id equal to the material id
        that is sent along with the other material information to the frontend on the inital fetch
    */

    return (
        <div className="dash-content">
            <div className="px-3">
                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <h4 className="text-center text-dark mb-5">Course Materials CS{selectedCourse}</h4>
                            <DownloadMaterial
                                courseID={selectedCourse}
                                entries={materialList}
                                fetchFiles={fetchFiles}
                            />
                            <UploadMaterial 
                                courseID={selectedCourse} 
                                upload={uploadPost} 
                                message={uploadMessage} 
                                setMessage={setUploadMessage} 
                                messageStyle={messageRef.current}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileSharing;
