import React from "react";
import { useState, useEffect, useRef } from "react";
import ProblemInfo from "./ProblemInfo";
import axios from "axios";
function ViewProblems({ courseID }) {
  const [selectedProblem, setSelectedProblem] = useState(-1);
  const [problemList, setProblemList] = useState(null);
  const cateMap = useRef(null);
  const categoryRef = useRef(null);
  //Helper function that fetches the problems and sorts them into maps/refs to be used for data display
  const fetchProblems = () => {
    axios
      .get(`https://api.sam.cs.mtu.edu/api/course/practiceproblems/${courseID}`)
      .then((response) => {
        let problemMap = new Map();
        let categoryMap = new Map();
        let categorySet = new Set();
        response.data.forEach((entry) => {
          categorySet.add(entry.topic);
          problemMap.set(parseInt(entry.problemID), {
            name: entry.problemName,
            topic: entry.topic,
            description: entry.description,
            hint: entry.hint,
            comments: entry.comments,
          });
          if (categoryMap.has(entry.topic)) {
            let previousValue = categoryMap.get(entry.topic);
            previousValue.push({
              id: entry.problemID,
              name: entry.problemName,
            });
            categoryMap.set(entry.topic, previousValue);
          } else {
            categoryMap.set(entry.topic, [
              {
                id: entry.problemID,
                name: entry.problemName,
              },
            ]);
          }
        });
        categoryRef.current = categorySet;
        cateMap.current = categoryMap;
        setProblemList(problemMap);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProblems();
  }, [courseID]);

  //Helper function that gets all problems for the select
  const getAllProblems = () => {
    let returnArray = [];
    console.log(categoryRef.current);
    categoryRef.current.forEach((category) => {
      returnArray.push(
        <optgroup
          key={`problemOptgroup-${category}-${courseID}`}
          label={category}
          className="problemSelectionOptionGroup"
        >
          {getAllProblemsInCategory(category)}
        </optgroup>,
      );
    });
    return returnArray;
  };

  //Helper function to return all the problems in a given category
  const getAllProblemsInCategory = (category) => {
    let selectArray = [];
    cateMap.current.get(category).forEach((problem) => {
      selectArray.push(
        <option
          key={`problemIn${category}-${problem.id}`}
          className="practiceProblemSelectionOption"
          onClick={checkSelection}
          value={problem.id}
        >
          {problem.name}
        </option>,
      );
    });
    return selectArray;
  };

  const checkProblemSelection = (event) => {
    const options = document.getElementsByClassName(
      "practiceProblemSelectionOption",
    );
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        setSelectedProblem(parseInt(options[i].value));
        break;
      }
    }
  };

  //Onclick handler to update the selected problem state
  const checkSelection = (event) => {
    setSelectedProblem(parseInt(event.target.value));
  };
  return (
    <div>
      {/* <h1 className="practiceProblemHeader">View Practice Problems</h1> */}
      <div className="">
        <select
          onChange={checkProblemSelection}
          defaultValue={selectedProblem}
          className="form-select"
        >
          {problemList !== null ? (
          <>
            {" "}
            <option
              value={-1}
              onClick={checkSelection}
            >
            {categoryRef.current?.size>0?"Please make a selection":"No problems available"}
            </option>
            {getAllProblems()}
          </>
          ) : (
            <option value={-1}>
              Loading...
            </option>
          )}
        </select>
      </div>
      {selectedProblem !== -1 ? (
        <ProblemInfo data={problemList.get(parseInt(selectedProblem))} />
      ) : null}
    </div>
  );
}

export default ViewProblems;
