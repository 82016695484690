import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Loader from "../Loader.jsx";
import ConfirmationModal from "./ConfirmationModal";
import { useAppState } from "../../store/AppStateContext";
import Empty from "../Empty.jsx";
function ViewAskSAM() {
  const [questionEntries, setQuestionEntries] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [askSamMessage, setAskSamMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const clearMessage = useRef(null);
  const { selectedCourse, setSelectedCourse } = useAppState();

  // Effect to clear messages after 10 seconds
  useEffect(() => {
    if (askSamMessage !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setAskSamMessage(null);
      }, 10000);
    }
  }, [askSamMessage]);
  const fetchAskSam = () => {
    axios
      .get(`https://api.sam.cs.mtu.edu/api/sam/asksams/${selectedCourse}`)
      .then((response) => {
        if (response.data.length !== 0) {
          makeQuestionMap(response.data);
        } else {
          setQuestionEntries(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedCourse !== 0 && selectedCourse !== null) fetchAskSam();
  }, [selectedCourse]);

  // Clear messages on selection change
  useEffect(() => {
    if (currentSelection !== 0) {
      clearTimeout(clearMessage.current);
      setAskSamMessage(null);
    }
  }, [currentSelection]);

  // Helper function to generate the question map from entries
  const makeQuestionMap = (data) => {
    const questionMap = new Map();
    data.forEach((askSam) => {
      questionMap.set(askSam.id, {
        question: askSam.question,
        whence: askSam.submissionTime,
        submittedBy: askSam.fullName,
        studentEmail: askSam.email,
      });
    });
    setQuestionEntries(questionMap);
  };

  // Generate select dropdown for questions
  const getQuestionList = () => (
    <select
      className="form-select mb-3"
      defaultValue={currentSelection}
      onChange={(e) => setCurrentSelection(parseInt(e.target.value))}
    >
      <option value={0}>Please select a question</option>
      {questionEntries &&
        Array.from(questionEntries.keys()).map((key) => (
          <option key={key} value={key}>
            Question from {questionEntries.get(key).submittedBy} on{" "}
            {questionEntries.get(key).whence}
          </option>
        ))}
    </select>
  );

  // Confirm delete action
  const confirmDelete = () => {
    setShowDeleteModal(true); // Show the modal
  };

  // Delete the selected question
  const deleteQuestion = () => {
    setAskSamMessage("Processing...");
    axios
      .delete(
        `https://api.sam.cs.mtu.edu/api/sam/asksam/delete/${currentSelection}`,
        { withCredentials: true }
      )
      .then(() => {
        const updatedEntries = new Map(questionEntries);
        updatedEntries.delete(currentSelection);
        setQuestionEntries(updatedEntries);
        setAskSamMessage("Question Deleted!");
        setCurrentSelection(0);
        setShowDeleteModal(false); // Close modal after delete
      })
      .catch(() => {
        setAskSamMessage("Delete Failed!");
        setShowDeleteModal(false); // Close modal if delete fails
      });
  };

  // Display selected question's information
  const getQuestionInfo = () => {
    const questionInfo = questionEntries.get(currentSelection);
    return (
      <>
        <h5 className="text-primary mb-2">
          Submitted by {questionInfo.submittedBy} on {questionInfo.whence}
        </h5>
        <p className="text-muted mb-4">
          Student's Email: {questionInfo.studentEmail}
        </p>
        <textarea
          className="form-control mb-3"
          value={questionInfo.question}
          readOnly
          rows="4"
        />
        <button className="myDanBtn w-100" onClick={confirmDelete}>
          Delete Question
        </button>
      </>
    );
  };

  return (
    <>
      {questionEntries !== null && questionEntries === false ? (
        <>
          <div className="dash-content">
            <div className="px-3">
              <h4 className="text-center mb-5 text-dark mt-5">
               Ask SAMs 
              </h4>
              <div className="container card border-0 p-4 my-5">
                <div className="row justify-content-center">
                  <Empty text={"No Data Available"} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : questionEntries===null?<Loader/>:(
        <>
          <div className="dash-content">
            <div className="d-flex align-items-center justify-content-center mt-5">
              <div
                className="card p-4"
                style={{ maxWidth: "600px", width: "100%" }}
              >
                <h4 className="text-center mb-4 text-dark">
                  View Ask SAM Questions
                </h4>
                {questionEntries ? getQuestionList() : <Loader />}
                {currentSelection !== 0 && getQuestionInfo()}
                {askSamMessage && (
                  <div
                    className="alert alert-info mt-3 text-center"
                    role="alert"
                  >
                    {askSamMessage}
                  </div>
                )}
              </div>

              {/* Delete Confirmation Modal */}
              <ConfirmationModal
                show={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this question?"
                onConfirm={deleteQuestion}
                onCancel={() => setShowDeleteModal(false)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewAskSAM;
