import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "../components/Dashboard/Navbar.css";
import AdvisorNavbar from '../components/DashboardAdvisor/Navbar.js';
import { Navigate, useLocation, Outlet, useNavigate, useParams } from "react-router";
import { Context } from "./App";
import { useAppState } from "../store/AppStateContext.js";

const AdvisorDashboard = () => {
  const [userInfo, setUserInfo] = useContext(Context);
  const { selectedScheduleID, setSelectedScheduleID, selectedSession, setSelectedSession } = useAppState();
  const [sessions, setSessions] = useState([]);
  const [sessionsTemp, setSessionsTemp] = useState([]);
  const [attendanceInfo, setAttendanceInfo] = useState(null);
  const [sessionList, setSessionList] = useState(null);
  const [page, setPage] = useState("");
  const [loadingState, setLoadingState] = useState(true);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchData = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/advisor/getcourses")
      .then((response) => {
        console.log(response?.data);
        setSessions(response?.data?.returnArr);
        let sessionVals = [];
        response?.data?.returnArr.forEach((entry) =>
          sessionVals.push(`${entry?.courseID}-${entry?.semester}-${entry?.year}`)
        );
        setSessionsTemp(sessionVals);
        let sess = response?.data?.returnArr[0];
        let sessID = `${sess?.courseID}-${sess?.semester}-${sess?.year}`;
        setSelectedScheduleID(sess?.scheduleID);
        if (params?.sessionID) {
          if (sessionVals?.includes(params?.sessionID)) {
            updateSelectedSession(params?.sessionID);
          } else {
            updateSelectedSession(sessID);
            const pieces = location.pathname.split("/");
            navigate(`/advisor/${pieces[2]}/${sessID}`);
          }
        } else {
          if (selectedSession === null) {
            updateSelectedSession(sessID);
          } else {
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if(!userInfo.Signin){
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }else if(!userInfo.Roles.Advisor){
      navigate('/');
    }else{
      fetchData();
    }
  }, []);
  
  useEffect(() => {
    if (!userInfo.Signin) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }else if(!userInfo.Roles.Advisor){
      navigate('/');
    } else if (selectedSession !== null) {
      const path = location.pathname;
      let pathParts = path.split("/");
      console.log(pathParts);
      if (pathParts.length === 2 || pathParts[2].length === 0) {
        console.log("This");
        navigate(`/advisor/home/${selectedSession}`);
      } else if (
        sessionsTemp?.length > 0 &&
        !sessionsTemp?.includes(params?.sessionID)
      ) {
        const pieces = location.pathname.split("/");
        updateSelectedSession(sessionsTemp[0]);
        navigate(`/advisor/${pieces[2]}/${sessionsTemp[0]}`);
      }
    }
  }, [location.pathname, selectedSession]);

  const updateSelectedSession = (data) => {
    setSelectedSession(data);
    console.log(sessions);
    let sess = sessions?.filter(sess => `${sess?.courseID}-${sess?.semester}-${sess?.year}` == data);
    console.log(sess);
    console.log(sess[0]?.scheduleID);
    setSelectedScheduleID(sess[0]?.scheduleID);
  }

  useEffect(() => {
    updateSelectedSession(selectedSession);
  }, [sessions]);

  return !userInfo.Signin ? (
    <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />
  ) : (
    <div className="App">
      <AdvisorNavbar
        sessions={sessions}
        selectedSession={selectedSession}
        setSelectedSession={updateSelectedSession}
      />
      <div className="dash-content">
        <div className="px-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdvisorDashboard;
