import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";
import SamSessions from "./SamSessions";
import StudentSessions from "./StudentSessions";

const ManageStudents = () => {
  const [type, setType] = useState("");
  const [showSessionsModal, setShowSessionsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  const [courseSessions, setCourseSessions] = useState([]);
  // Search and Pagination States
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Number of rows per page

  const refresh = () => {
    let p1 = axios.get("https://api.sam.cs.mtu.edu/api/admin/getStudents");
    let p2 = axios.get(
      "https://api.sam.cs.mtu.edu/api/admin/getCourseSessions"
    );
    Promise.all([p1, p2])
      .then((response) => {
        setStudents(response[0]?.data?.students);
        setCourseSessions(response[1]?.data?.courses);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (message != null) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Filter SAMs based on the search term
  const filteredStudents = students.filter(
    (students) =>
      students.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      students.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      students.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredStudents.length / rowsPerPage);
  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showAdd = () => {
    setUsername("");
    setName("");
    setEmail("");

    setType("add");
    setShowModal(true);
  };

  const addStudent = () => {
    if (username && name && email) {
      axios
        .post(
          "https://api.sam.cs.mtu.edu/api/admin/addStudent",
          {
            username: username,
            email: email,
            fullName: name,
          },
          { withCredentials: true }
        )
        .then(() => {
          setError(false);
          setMessage("Student Added Successfully!");
          refresh();
        })
        .catch(() => {
          setError(true);
          setMessage("Something went wrong! Please try again!");
        });
    } else {
      setError(true);
      setMessage("Please fill all fields!");
    }
  };

  const showEdit = (username, name, email) => {
    setUsername(username);
    setName(name);
    setEmail(email);

    setType("edit");
    setShowModal(true);
  };

  const editStudent = () => {
    try {
      if (username && name && email) {
        axios
          .patch(`https://api.sam.cs.mtu.edu/api/admin/updateStudent`, {
            username: username,
            fullName: name,
            email: email,
          })
          .then((response) => {
            setError(false);
            setMessage("Student Edited Successfully!");
            refresh();
          })
          .catch((error) => {
            console.log(error);
            setError(true);
            setMessage("Something went wrong! Please try again!");
          });
      } else {
        setError(true);
        setMessage("Please fill all fields!");
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setMessage("Something went wrong! Please try again!");
    }
  };

  const showDelete = (username) => {
    setUsername(username);
    setShowDeleteModal(true);
  };

  const deleteStudent = () => {
    axios
      .patch(`https://api.sam.cs.mtu.edu/api/admin/deleteStudent`, {
        username: username,
      })
      .then(() => {
        setError(false);
        setMessage("Student has been deleted.");
        refresh();
      })
      .catch(() => {
        setError(true);
        setMessage("Delete failed!");
      });
  };

  const manageSessions = (username, courses) => {
    setUsername(username);
    setCourses(courses);

    setShowSessionsModal(true);
  };

  return (
    <>
      <div className="container mt-4 col-12 col-lg-8 col-md-8 mb-5">
        <h4 className="text-left text-dark mb-5 mt-5 col-md-6">
          Students Table
        </h4>

        {/* Add Student Button */}
        <button className="mySmBtn mb-3" onClick={() => showAdd()}>
          Add Student
        </button>

        {/* Search Input */}
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search Students..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {message && (
          <p
            className={`alert ${!error ? "alert-success" : "alert-danger"} mt-3`}
          >
            {message}
          </p>
        )}

        {/* Table */}
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Course Schedules</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedStudents.map((data, idx) => (
                <tr key={idx}>
                  <td>
                    <code>{(currentPage - 1) * rowsPerPage + idx + 1}</code>
                  </td>
                  <td>{data?.username}</td>
                  <td>{data?.fullName}</td>
                  <td>{data?.email}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() =>
                        manageSessions(data?.username, data?.courses)
                      }
                    >
                      Manage Sessions
                    </button>
                  </td>
                  <td>
                    <i
                      className="bi bi-pencil-square text-success"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={() =>
                        showEdit(
                          data?.username,
                          data?.fullName,
                          data?.email,
                          data?.picture,
                          data?.description
                        )
                      }
                    ></i>
                    <i
                      className="bi bi-trash text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => showDelete(data?.username)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <div>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={`btn btn-sm mx-1 ${currentPage === i + 1 ? "mySmBtn" : "mySecSmBtn"}`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this student?"
        onConfirm={() => {
          deleteStudent();
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />

      {/* Manage Sessions Modal */}
      {showSessionsModal && (
        <StudentSessions
          username={username}
          courses={courses}
          courseSessions={courseSessions}
          setShowModal={setShowSessionsModal}
          setError={setError}
          setMessage={setMessage}
          refresh={refresh}
        />
      )}

      {/* Add/Edit Modal */}
      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">
                  {type === "add" ? "Add New Student" : "Edit Student"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    type === "edit" ? editStudent() : addStudent();
                    setShowModal(false);
                  }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageStudents;
