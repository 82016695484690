import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";

const ManageSemesters = () => {
    const [type, setType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [semester, setSemester] = useState("Fall");
    const [year, setYear] = useState("2024"); // Default year is 2024
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [semesters, setSemesters] = useState([]);
    // Search and Pagination States
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Number of rows per page

    const years = Array.from({ length: 7 }, (_, index) => (2024 + index)); // Generate years 2024 to 2030
    
    const refresh = ()=>{
      axios.get("https://api.sam.cs.mtu.edu/api/admin/getSemesters")
      .then((response)=>{
        setSemesters(response?.data?.semesters);
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    useEffect(()=>{
      refresh();
    },[]);

    useEffect(() => {
        if (message != null) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [message]);

    // Filter semesters based on the search term
    const filteredSemesters = semesters.filter((semester) =>
        semester.semester.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(semester.year).includes(searchTerm)
    );

    // Calculate pagination
    const totalPages = Math.ceil(filteredSemesters.length / rowsPerPage);
    const paginatedSemesters = filteredSemesters.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showAdd = () => {
        setSemester("Fall");
        setYear("2024");
        setStartDate("");
        setEndDate("");

        setType("add");
        setShowModal(true);
    };

    const addSemester = () => {
        if (semester && year && startDate && endDate) {
            axios.post(
                `https://api.sam.cs.mtu.edu/api/admin/addSemester`,
                {
                    semester,
                    year,
                    startDate,
                    endDate,
                }
            )
                .then(() => {
                    setError(false);
                    setMessage("Semester Added Successfully!");
                    refresh();
                })
                .catch(() => {
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
        } else {
            setError(true);
            setMessage("Please fill all fields!");
        }
    };

    const showEdit = (semester) => {
        setSemester(semester.semester);
        setYear(semester.year);
        setStartDate(new Date(semester.startDate).toISOString().split("T")[0]);
        setEndDate(new Date(semester.endDate).toISOString().split("T")[0]);

        setType("edit");
        setShowModal(true);
    };

    const editSemester = () => {
        if (semester && year && startDate && endDate) {
            axios.patch(
                `https://api.sam.cs.mtu.edu/api/admin/updateSemester`,
                {
                    semester,
                    year,
                    startDate,
                    endDate,
                }
            )
                .then(() => {
                    setError(false);
                    setMessage("Semester Edited Successfully!");
                    refresh();
                })
                .catch(() => {
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
        } else {
            setError(true);
            setMessage("Please fill all fields!");
        }
    };

    const showDelete = (semesterID) => {
        setYear(semesterID);
        setShowDeleteModal(true);
    };

    const deleteSemester = () => {
        axios.patch(
            `https://api.sam.cs.mtu.edu/api/admin/deleteSemester`,
            { semester, year }
        )
            .then(() => {
                setError(false);
                setMessage("Semester has been deleted.");
                refresh();
            })
            .catch(() => {
                setError(true);
                setMessage("Delete failed!");
            });
    };

    return (
        <>
            <div className="container col-12 col-lg-8 col-md-8">
                <h4 className="text-left text-dark mb-5 mt-5 col-md-6">
                    Semesters Table
                </h4>

                <button
                    className="mySmBtn justify-content-end mb-2"
                    onClick={() => showAdd()}
                >
                    Add Semester
                </button>

                {/* Search Input */}
                <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search Semesters..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {message && (
                    <p className={`alert ${!error ? "alert-success" : "alert-danger"} mt-3`}>
                        {message}
                    </p>
                )}

                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Semester</th>
                                <th>Year</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedSemesters.map((semester, idx) => (
                                <tr key={idx}>
                                    <td><code>{(currentPage - 1) * rowsPerPage + idx + 1}</code></td>
                                    <td>{semester.semester}</td>
                                    <td>{semester.year}</td>
                                    <td>{new Date(semester.startDate).toLocaleDateString()}</td>
                                    <td>{new Date(semester.endDate).toLocaleDateString()}</td>
                                    <td>
                                        <i
                                            className="bi bi-pencil-square text-success"
                                            style={{ cursor: "pointer", marginRight: "10px" }}
                                            onClick={() => showEdit(semester)}
                                        ></i>
                                        <i
                                            className="bi bi-trash text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => showDelete(semester.year)}
                                        ></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span>Page {currentPage} of {totalPages}</span>
                    <div>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i}
                                className={`btn btn-sm mx-1 ${currentPage === i + 1 ? "mySmBtn" : "mySecSmBtn"}`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this semester?"
                onConfirm={() => {
                    deleteSemester();
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />

            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-dark">
                                    {type === "add" ? "Add New Semester" : "Edit Semester"}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label>Semester</label>
                                    <select
                                        className="form-control"
                                        value={semester}
                                        onChange={(e) => setSemester(e.target.value)}
                                    >
                                        <option value="Fall">Fall</option>
                                        <option value="Spring">Spring</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Year</label>
                                    <select
                                        className="form-control"
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                    >
                                        {years.map((yr) => (
                                            <option key={yr} value={yr}>
                                                {yr}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Start Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>End Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        type === "edit" ? editSemester() : addSemester();
                                        setShowModal(false);
                                    }}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ManageSemesters;
