import React, { useContext, useState, useEffect } from "react";
import "../Dashboard/Navbar.css";
import * as Fa from "react-icons/fa";
import * as Ai from "react-icons/ai";
import * as Ri from "react-icons/ri";
import { Link } from "react-router";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";
import homeLogo from "../../assets/homeicon.svg";
import { Context } from "../../routes/App";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal.jsx";
import { useNavigate, useLocation} from "react-router";
const AdvisorNavbar = ({ sessions, selectedSession, setSelectedSession }) => {
  const [sidebar, setSidebar] = useState(false);
  const [userInfo, setUserInfo, manualLogout] = useContext(Context);
  const [showSignout, setShowSignout] = useState(false);

  const location = useLocation();
  const [currentTab, setCurrentTab] = useState();
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const paths = location.pathname.split("/");
    setCurrentTab(paths[2] || '');
  }, [location]);
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    setShowSignout(true);
  };

  const updateSelected = (e) => {
    const paths = location.pathname.split("/");
    console.log(e.target.value);
    setSelectedSession(e.target.value);
    navigate(`/advisor/${paths[2]}/${e.target.value}`);
  };

  return (
    <>
      {showSignout ? (
        <ConfirmationModal
          show={showSignout}
          title={"Confirm Signout"}
          message={"Are you sure you want to signout?"}
          onConfirm={manualLogout}
          onCancel={() => setShowSignout(false)}
        />
      ) : null}
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <Fa.FaBars onClick={showSidebar} />
          </Link>
          <Link to="/">
            <img
              id="homeButtonNavLink"
              src={homeLogo}
              draggable={false}
              alt="home"
            />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            {sidebar ? (
              <li className="navbar-toggle">
                <Link to="#" onClick={showSidebar} className="menu-bars">
                  <Ai.AiOutlineClose />
                </Link>
              </li>
            ) : (
              <li className="nav-text">
                <Link to="/">
                  <img
                    id="homeButtonNavLink"
                    src={homeLogo}
                    draggable={false}
                    alt="home"
                  />
                </Link>
              </li>
            )}
            <li className="nav-text">
              <p id="navTextOverride">Session: {selectedSession}</p>
            </li>
            {SidebarData?.map(({ cName, path, title, icon }, index) => {
              return (
                <li
                  key={index}
                  className={`${cName} ${
                    currentTab === path ||
                    (currentTab === "" && path === "home")
                      ? "selectedNav"
                      : ""
                  }`}
                >
                  <Link to={`/advisor/${path}/${selectedSession}`}>
                    {icon}
                    <span style={{ paddingLeft: "10px" }}>{title}</span>
                  </Link>
                </li>
              );
            })}
            <li className="nav-text navT">
              <Link draggable={false} onClick={() => navigate("/")}>
                <Ri.RiArrowGoBackFill />
                <span style={{ paddingLeft: "10px" }}>Return Home</span>
              </Link>
            </li>
            <li className="nav-text navT">
              <Link
                to="/"
                draggable={false}
                state={{ logout: true }}
                onClick={handleLogout}
              >
                <Ri.RiLogoutBoxLine />
                <span style={{ paddingLeft: "10px" }}>Log Out</span>
              </Link>
            </li>
            <hr
              style={{
                borderColor: "white",
              }}
            />
            <li className="nav-text">
              <Link>Switch Sessions</Link>
            </li>

            <div className="mx-4">
              {selectedSession !== null ? (
                <select
                  className="form-select"
                  onChange={updateSelected}
                  value={selectedSession}
                >
                  {sessions?.map((session, idx) => (
                    <option key={idx} value={`${session?.courseID}-${session?.semester}-${session?.year}`}>
                      {`${session?.courseID}-${session?.semester}-${session?.year}`}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
};

export default AdvisorNavbar;
