import React, { useState, useEffect } from "react";
import axios from "axios";
import FormData from "form-data";
import Loader from "../Loader.jsx";
import PreviewPfp from "./PreviewPfp.jsx";
function UpdateProfile() {
  axios.defaults.withCredentials = true;
  const [messages, setMessages] = useState("");
  const [success, setSuccess] = useState(0);
  const [profile, setProfile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // State for image preview
  const [showPreview, setShowPreview] = useState(null);

  const updateDescription = () => {
    setMessages("Processing...");
    setSuccess(0);
    const newDesc = document.getElementById("samProfileUpdate1").value;
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/updateProfile", {
        withCredentials: true,
        description: newDesc,
      })
      .then((response) => {
        setMessages("Profile updated successfully");
        setSuccess(1);
      })
      .catch((error) => {
        setMessages("Request failed, please retry later");
        setSuccess(2);
      });
  };

  const handleImageError = (event) => {
    event.target.src = "https://api.sam.cs.mtu.edu/defaults/no_photo.jpg";
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const previewURL = URL.createObjectURL(file);
    setShowPreview(previewURL);
  };

  const updatePhoto = (blob) => {
    setShowPreview(null);
    setMessages("Processing...");
    setSuccess(0);
    let form = new FormData();
    form.append("file", blob);
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/uploadPhoto", form, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setMessages("Photo updated successfully");
        setSuccess(1);
        fetchProfile();
//        window.location.reload();
      })
      .catch((error) => {
        setMessages("Photo update failed");
        setSuccess(2);
      });
  };

  const fetchProfile = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/myprofile")
      .then((response) => {
        setProfile(Object.values(response.data));
      })
      .catch((error) => console.log(error));
  };
  const cancelUpload = () => {
    setShowPreview(null);
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (messages != null && messages !== "Processing...") {
      setTimeout(() => {
        setMessages(null);
        setSuccess(0);
      }, 10000);
    }
  }, [messages]);

  return (
    <>
      {showPreview !== null ? (
        <PreviewPfp
          photoUrl={showPreview}
          uploadAction={updatePhoto}
          cancelAction={cancelUpload}
        />
      ) : null}
      <div className="dash-content">
        <div className="container mt-4">
          {profile === null ? (
            <>
              <div className="row justify-content-center align-items-center">
                <Loader />
              </div>
            </>
          ) : (
            <>
              <div className="p-3">
                {messages ? (
                  <p
                    className={`alert alert-${
                      success == 0
                        ? "info"
                        : success == 1
                          ? "success"
                          : "danger"
                    } mt-3`}
                  >
                    {messages}
                  </p>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="my-3 text-dark">Profile Picture</h5>
                      </div>
                      <div className="card-body">
                        {profile !== null ? (
                          <div
                            className="position-relative d-inline-block text-center"
                            style={{ width: "100%" }}
                          >
                            {/* Display previewImage if available; otherwise, show the current profile image */}
                            <img
                              onError={handleImageError}
                              className="img-thumbnail mb-2"
                              src={
                                previewImage ||
                                `https://api.sam.cs.mtu.edu/${profile[1]}`
                              }
                              alt="Profile"
                              style={{ width: "250px", aspectRatio: "4/4" }}
                              draggable={false}
                            />
                            <button
                              className="position-absolute border-0 rounded-circle"
                              style={{
                                bottom: "0px",
                                right: "160px",
                                width: "40px",
                                aspectRatio: "1/1",
                              }}
                              onClick={() =>
                                document
                                  .getElementById("samPhotoUpload1")
                                  .click()
                              }
                            >
                              +
                            </button>
                          </div>
                        ) : null}
                        <div className="mt-3">
                          <input
                            type="file"
                            id="samPhotoUpload1"
                            onChange={handleImageChange} // Trigger handleImageChange on file select
                            accept="image/png, image/jpeg, image/jpg"
                            className="form-control mt-2"
                            hidden
                          />
                          <button
                            id="samDashboardUploadButton1"
                            className="myBtn mt-2 d-none"
                            onClick={() => {
                              updatePhoto();
                              document
                                .getElementById("samDashboardUploadButton1")
                                .classList.add("d-none");
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 justify-content-start align-self-stretch">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="my-3 text-dark">Profile Description</h5>
                      </div>
                      <div className="card-body d-flex flex-column justify-content-between">
                        {profile === null ? (
                          <textarea
                            rows="7"
                            id="samProfileUpdateLoading1"
                            className="form-control"
                            defaultValue={""}
                            disabled
                          ></textarea>
                        ) : (
                          <textarea
                            rows="7"
                            id="samProfileUpdate1"
                            className="form-control"
                            placeholder={profile[0]}
                          ></textarea>
                        )}
                        <button
                          className="myBtn w-25"
                          onClick={updateDescription}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
