import React from "react";
const classNames = {
  2311: "Discrete Structures",
  2321: "Data Structures",
  3331: "Concurrent Computing",
  3411: "Systems Programming",
};
function SamInfo(sams) {
  console.log(sams)
  //Helper function to render information regarding sams
  const renderSam = () => {
    let returns = [];

    const handleImageError = (event) => {
      event.target.src = "https://api.sam.cs.mtu.edu/defaults/no_photo.jpg";
    };
    for (const [key, value] of sams.sams) {
      returns.push(
        <div key={key} className="col-md-6 col-lg-3">
          <div className="team-thumb text-center">
            <figure className="blog-img bg-white d-flex align-items-center justify-content-center" style={{ padding: "50px 30px", borderRadius: "10px 10px 0 0" }}>
              <div className="rounded-circle shadow bg-white" style={{ width: "150px", height: "150px", overflow: "hidden", }}>
                <img
                src={`https://api.sam.cs.mtu.edu/${value.picture}`}
                    onError={handleImageError} 
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                    alt="SAM's Photo"
                    draggable={false} 
                />
              </div>
            </figure>
            <div className="text" style={{ borderRadius: "0 0 10px 10px" }}>
              <h4 className="title"><a>{key}</a></h4>
              <p className="designation th-cl">
                {value?.courses.map((course) => `CS${course}`).join(', ')}
              </p>
            </div>
          </div>
      </div>
      );
    }
    return returns;
  };

  

  return renderSam();
}

export default SamInfo;
