import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Outlet,
  useParams,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router";
import Navbar from "../components/Dashboard/Navbar";
import "../components/Dashboard/Navbar.css";
import axios from "axios";
import { Context } from "./App";
import UserProfile from "../userData/UserProfile";
import empty from "../assets/images/empty.svg";
import Empty from "../components/Empty";
import { useAppState } from "../store/AppStateContext";
import { GiConsoleController } from "react-icons/gi";

const SamDashboard = () => {
  const [userInfo, setUserInfo] = useContext(Context);
  const { selectedCourse, setSelectedCourse } = useAppState();
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState("");
  const [loadingState, setLoadingState] = useState(true);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const getScheduleData = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/getscheduleinfo")
      .then((response) => {
        let coursesTemp = Object.values(response.data[2]); //This is a list of all courses the requestor is a SAM for
        let allSessions = Object.values(response.data[3]); //This includes non-reoccuring and reoccuring sessions
        let courseVals = [];
        coursesTemp.forEach((entry) =>
          courseVals.push(parseInt(entry.courseID))
        );
        setCourses(courseVals);
        if (params?.courseID) {
          if (courseVals?.includes(Number(params?.courseID))) {
            setSelectedCourse(Number(params?.courseID));
          } else {
            setSelectedCourse(courseVals[0]);
            const pieces = location.pathname.split("/");
            navigate(`/sam/${pieces[2]}/${courseVals[0]}`);
          }
        } else {
          if (selectedCourse === 0 || selectedCourse === undefined) {
            setSelectedCourse(courseVals[0]);
          } else {
          }
        }
      })
      .catch((error) => console.log(error));
    setLoadingState(false);
  };

  useEffect(() => {
    if (!userInfo.Signin) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    } else if(!userInfo.Roles.SAM){
      navigate('/');
    }else{
      getScheduleData();
    }
  }, []);
  
  useEffect(() => {
    if (!userInfo.Signin) {
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }else if(!userInfo.Roles.SAM){
      navigate('/');
    } else if (selectedCourse !== 0 && selectedCourse !== undefined) {
      const path = location.pathname;
      let pathParts = path.split("/");
      if (pathParts.length === 2 || pathParts[2].length === 0) {
        navigate(`/sam/home/${selectedCourse}`);
      } else if (
        courses.length > 0 &&
        !courses.includes(Number(params?.courseID))
      ) {
        const pieces = location.pathname.split("/");
        navigate(`/sam/${pieces[2]}/${courses[0]}`);
      }
    }
  }, [location.pathname, selectedCourse]);

  //Helper function to add a sessionList
  const addSession = (sessionInfo) => {
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/addsession", {
        courseID: selectedCourse,
        sessionDate: sessionInfo[0],
        sessionTime: sessionInfo[1],
        sessionLocation: sessionInfo[2],
        sessionNotes: sessionInfo[3],
        endtime: sessionInfo[4],
      })
      .then((response) => {
        getScheduleData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Helper function to update a sessionList
  const updateSession = (params) => {
    axios
      .patch("https://api.sam.cs.mtu.edu/api/sam/updatesession", {
        date: params[0],
        time: params[1],
        location: params[2],
        notes: params[3],
        endtime: params[4],
        sessionID: params[5],
      })
      .then(() => {
        getScheduleData();
        return 0;
      })
      .catch((error) => {
        console.log(error);
        return 1;
      });
  };

  //Helper function to delete a sessionList
  const deleteSession = (sessionID) => {
    axios
      .delete(`https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`)
      .then(() => getScheduleData())
      .catch((error) => console.log(error));
  };

  //Helper function to 'cancel'(delete from db) a specific session
  const cancelSession = (sessionID) => {
    return axios.delete(
      `https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`
    );
    //Cancel logic goes here
  };

  return (
    <div className="App">
      {!loadingState ? (
        <>
          <Navbar
            courses={courses}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            setPage={setPage}
            currentPage={page}
          />
          <Outlet />
        </>
      ) : null}
    </div>
  );
};

export default SamDashboard;
