import React, { useState, useEffect, useRef } from "react";
import SessionUpdateModal from "./SessionUpdateModal.jsx";
import "../../Styles/sam.css";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import check from "../../assets/check.svg";
import cancel from "../../assets/cancel.svg";
import ConfirmationModal from "./ConfirmationModal";
function WeeklySessions({
  scheduleInfo,
  weeklySessionAdder,
  weeklySessionUpdater,
  weeklySessionDeleter,
  weeklySessionJoiner,
  weeklySessionLeaver,
  currentCourse,
  setCurrentCourse,
  tableData,
}) {
  const [sessionMap, setSessionMap] = useState(new Map());
  const [currentSession, setCurrentSession] = useState(0);
  const [sessionList, setSessionList] = useState(null);
  const [sessionMessage, setSessionMessage] = useState(null);
  const messageTimeout = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const dataRef = useRef();
  useEffect(() => {
    if (sessionMessage !== null) {
      clearTimeout(messageTimeout.current);
      messageTimeout.current = setTimeout(() => {
        setSessionMessage(null);
      }, 10000);
    }
  }, [sessionMessage]);
  //Resetting session message on course change
  useEffect(() => {
    setSessionMessage(null);
  }, [currentCourse]);

  //Get sessions into a map for easy data access
  const getSessions = () => {
    let sessionMapTemp = new Map();
    scheduleInfo[1]?.forEach((session) => {
      console.log(session);
      if (!sessionMapTemp.has(session.courseID)) {
        sessionMapTemp.set(session.courseID, new Map());
      }
      const weekdayMap = sessionMapTemp.get(session.courseID);
      if (!weekdayMap.has(session.weekday)) {
        weekdayMap.set(session.weekday, []);
      }
      weekdayMap.get(session.weekday).push({
        weeklySessionID: session.weeklySessionID,
        location: session.sessionLocation,
        time: session.sessionTime,
        endtime: session.endTime,
        status: session.status
      });
    });
    setSessionMap(sessionMapTemp);
  };

  //Helper function to get time in readable format
  const getFormattedTime = (time) => {
    let [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  //Updates the course entries whenever there is new schedule info passed in
  useEffect(() => {
    getSessions();
    setCurrentSession(0);
  }, [scheduleInfo]);
  useEffect(() => {
    getWeeklySessionItems();
  }, [sessionMap]);

  //Helper function to reset the session dropdown properly
  const resetSessionSelection = (message) => {
    setCurrentSession(0);
    setSessionMessage(message);
  };

  const confirmDeleteSession = (sessionID) => {
    setSessionToDelete(sessionID);
    setShowDeleteModal(true);
  };

  const deleteWeeklySession = () => {
    if (sessionToDelete !== null) {
      weeklySessionDeleter(sessionToDelete);
      resetSessionSelection("Session Deleted!");
      setShowDeleteModal(false);
      setSessionToDelete(null);
    }
  };


  //Helper function to get all of the sessions for the dropdown box
  const getSessionEntries = () => {
    let returnSet = [];
    if (sessionMap.get(parseInt(currentCourse)) !== undefined) {
      const mapIterator = sessionMap
        .get(parseInt(currentCourse))
        [Symbol.iterator]();
      for (const session of mapIterator) {
        session[1].forEach((entry) => {
          let currentID = entry.weeklySessionID;
          let selected = false;
          if (currentSession === parseInt(entry.weeklySessionID))
            selected = true;
          returnSet.push(
            <li
              sessionid={entry.weeklySessionID}
              key={entry.weeklySessionID}
              className="weeklyCourseSessionOption"
            >
              <p
                className={`sessionImmutableInfo `}
                style={{ margin: "0px" }}
              >
                {" "}
                {`${session[0]} at ${getFormattedTime(entry.time.slice(0, 5))} to ${getFormattedTime(entry.endtime)} in ${entry.location}`}
              </p>
              <span className="weeklySessionIconWrapper">
                {
                  entry.status===1?
                  <button value={entry.weeklySessionID} onClick={(e)=>weeklySessionLeaver(e.target.value)} className="btn btn-sm btn-warning">Leave Session</button>
                  :
                  <button value={entry.weeklySessionID} onClick={(e)=>weeklySessionJoiner(e.target.value)} className="btn btn-sm btn-warning">Join Session</button>
                }
                <i
                    className="bi bi-pencil-square text-success"
                    style={{ cursor: "pointer", marginLeft: "8px", marginRight: "8px" }}
                    onClick={() => {setCurrentSession(parseInt(event.target.getAttribute("value")))}}
                    value={currentID}
                ></i>
                <i
                    className="bi bi-trash text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmDeleteSession(currentID)}
                ></i>
              </span>
            </li>,
          );
        });
      }
    }
   returnSet.push(
    <li key="add-session" className="list-group-item text-left">
      <button className="btn btn-outline-primary" onClick={() => setCurrentSession(1)}>+ Add New Session</button>
    </li>,
  );

  return returnSet;
}

  const cancelOrDelete = (event) => {
    let value = parseInt(event.target.getAttribute("value"));
    if (value === currentSession) {
      setCurrentSession(null);
    } else {
      weeklySessionDeleter(value);
      resetSessionSelection("Session Deleted!");
    }
  };

  //Generating list of sessions to select to update
  const getWeeklySessionItems = () => {
    setSessionList(
      <ul className="weeklySessionOptions">{getSessionEntries()}</ul>,
    );
  };


  const updateSession = () => {
      let info = [
        dataRef?.current?.weekday, 
        dataRef?.current?.sessionTime, 
        dataRef?.current?.sessionLocation
      ]
      if(currentSession===1){
          info.push(dataRef?.current?.endTime);
          info.push(currentCourse);
          weeklySessionAdder(info);
      }else{
          info.push(currentSession);
          info.push(dataRef?.current?.endTime);
          weeklySessionUpdater(info);
      }
      
  };
  const getEditModal = ()=>{
    if(currentSession!==1){ 
      const session = scheduleInfo[1].find(entry=>entry.weeklySessionID===currentSession);
      dataRef.current=session;
      console.log(dataRef.current);
    }else{
      dataRef.current={
        courseID: currentCourse,
        sessionTime: null,
        endTime: null,
        weekday: "Monday",
        sessionLocation: null
      }
    }
    
    return(
      <SessionUpdateModal
        title={currentSession!==1?"Update Session":"Add Session"}
        sessionRef={dataRef}
        onConfirm={updateSession}
        onCancel={cancelUpdate}
      />
    );
  }
  
  const cancelUpdate = ()=>{
    dataRef.current=null;
    setCurrentSession(0);
  }
  const getSessionList = () => {
    return sessionList;
  };

  return (
    <div className="col-12 col-lg-6 col-md-8">
      <h5 className="text-left text-dark mb-3">Your Weekly Schedule</h5>
      <div className="card">
        {sessionList !== null ? getSessionList() : null}
        {currentSession!==0?getEditModal():null}
      </div>
      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this session?"
        onConfirm={deleteWeeklySession}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
}

export default WeeklySessions;
