import React, { useEffect, useState, useContext, useRef } from "react";
import UserProfile from "../userData/UserProfile";
import { Navigate, useLocation } from "react-router";
import { Context } from "./App";
import axios from "axios";
import SamInfo from "../components/StudentDashComponents/SamInfo";
import CourseAttendance from "../components/StudentDashComponents/CourseAttendance";

const Account = () => {

    const location = useLocation();
    
    const [userInfo, setUserInfo] = useContext(Context);
    const [courseData, setCourseData] = useState(null);
    let tempCourseList = {};
    let insertArray = [];

    useEffect(() => {
        if (!userInfo.Signin) {
          return;
        }
        let promise1 = axios
          .get("https://api.sam.cs.mtu.edu/api/student/attendance")
          .then((response) => {
            for (const courseInfo in response.data) {
              tempCourseList[`'${courseInfo}'`] = response.data[courseInfo];
            }
            insertArray[0] = tempCourseList;
          })
          .catch((error) => {
            throw new Error("Promise 1 failed");
          });
        let promise2 = axios
          .get("https://api.sam.cs.mtu.edu/api/student/sams")
          .then((response) => {
            let samMap = new Map();
            let dataArr = Object.entries(response.data);
            for (let i = 0; i < Object.entries(response.data).length; i++) {
              if (dataArr[i][1].length > 0) {
                let key = dataArr[i][0];
                dataArr[i][1].forEach((sam) => {
                  console.log(sam);
                  const desc = sam.desc;
                  const picture = sam.picture;
                  const fullName = sam.fullName;
                  if (samMap.has(sam.fullName)) {
                    let userData = samMap.get(fullName);
                    userData.courses.push(parseInt(key));
                    samMap.set(fullName, userData);
                  } else {
                    let userData = {
                      courses: [parseInt(key)],
                      desc: desc,
                      picture: picture,
                    };
                    samMap.set(fullName, userData);
                  }
                });
              }
            }
            insertArray[1] = samMap;
          })
          .catch((error) => {
            throw new Error("Promise 2 failed");
          });
        Promise.all([promise1, promise2])
          .then(() => {
            setCourseData(insertArray);
          })
          .catch((error) => {
            setCourseData(false);
          });
    }, [userInfo]);
    
    return (
        <>
        <div className="main-content flex-grow-1">
            {!userInfo.Signin ? <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} /> : <section className="pt-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 align-self-lg-center">
                            <div className="about-caption courseBanner text-center">
                                <h6 className="title">
                                    Welcome Back {UserProfile.getName()}
                                </h6>
                                {courseData===false?
                                    <p className="h5">No courses available</p>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
                
            
            {Array.isArray(courseData) && courseData[0] ? (
                <div className="container">
                    <CourseAttendance
                        key={`studentAttendanceComponent`}
                        courseAttendanceObj={courseData[0]}
                    />
                </div>
            ) : ""}
                
                
            {Array.isArray(courseData) && courseData[1] ? (
            <section className="th-bg pt-60 pb-60">
                <div className="container">
                    <div className="section-title-2 mb-67 text-center">
                        <div className="row d-lg-flex">
                            <div className="col-lg-12 col-md-12">
                                <h2 className="title m-0" style={{ color: 'black', fontSize: '30px' }}>Meet Your SAMs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <SamInfo key={`studentSams`} sams={courseData[1]} />
                    </div>
                </div>
            </section>
            ) : ""}
        </div>
        </>
    )
};

export default Account;
