import React, { useState, useEffect } from "react";
import axios from "axios";
import UserProfile from "../../userData/UserProfile";
const SamHome = () => {
  const [data, setData] = useState("");
  const [profile, setProfile] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/myprofile")
      .then((response) => {
        setProfile(Object.values(response.data));
      })
      .catch((error) => console.log(error));
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/homestats")
      .then((response) => {
        console.log(response);
        setData(response?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="dash-content">
      <div className="row m-3">
        <div className="col-12 mb-3">
          <h5 className="mb-0 mt-5 text-dark text-left">
            Welcome back, {UserProfile.getName()}
          </h5>
        </div>
        <div className="col-12 col-lg-3 col-md-6">
          <div
            className="card radius-15 mt-3"
            style={{ background: "#ffcc00" }}
          >
            <div className="card-body text-center">
              <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                <h5 className="mb-0 text-dark text-center">
                  {data?.materials}
                </h5>
                <p className="mb-3 text-dark text-center fw-bold">
                  Total Materials Available
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-md-6">
          <div
            className="card radius-15 mt-3"
            style={{ background: "#ffcc00" }}
          >
            <div className="card-body text-center">
              <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                <h5 className="mb-0 text-dark text-center">
                  {data?.sessionsRan}
                </h5>
                <p className="mb-3 text-dark text-center fw-bold">
                  Total Sessions Ran
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-md-6">
          <div
            className="card radius-15 mt-3"
            style={{ background: "#ffcc00" }}
          >
            <div className="card-body text-center">
              <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                <h5 className="mb-0 text-dark text-center">
                  {data?.studentsAttended}
                </h5>
                <p className="mb-3 text-dark text-center fw-bold">
                  Total Students Attended 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 col-md-6">
          <div
            className="card radius-15 mt-3"
            style={{ background: "#ffcc00" }}
          >
            <div className="card-body text-center">
              <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                <p className="mb-0 text-dark text-center fw-bold">{data?.nextSession || "-"}</p>
                <p className="mb-3 text-dark text-center fw-bold">
                  {data?.nextSession ? "Next Session" : "No Upcoming Session"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamHome;
