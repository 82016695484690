import React, { useState, useEffect, useRef } from "react";

function RosterModal({
  show,
  title,
  onConfirm,
  onCancel,
  sessions,
  setSchID,
  setFile,
}) {
  if (!show) return null;
  const [message, setMessage] = useState(null);
  const checkUpload = (event) => {
    if (event.target.files[0].type !== "text/csv") {
      setMessage("Please only upload a .csv file");
      event.target.value = null;
    } else {
      setMessage(null);
      setFile(event.target);
    }
  };

  const oldTimeout = useRef(null);
  useEffect(() => {
    if (message !== null && message !== undefined) {
      clearTimeout(oldTimeout.current);
      oldTimeout.current = setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  }, [message]);
  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark">
              {title || "Confirm Action"}
            </h5>
          </div>
          <div className="modal-body">
            <div className="form-group mb-3">
              <label>Upload Roster File</label>
              <br></br>
              <input
                accept=".csv"
                className="form-control-file"
                type="file"
                onChange={checkUpload}
              />
            </div>
            <div className="form-group mb-3">
              <label>Course Section</label>
              <select
                className="form-control"
                onChange={(e) => setSchID(e.target.value)}
              >
                {sessions.map((session) => (
                  <option
                    key={`CS${session?.courseID} ${session?.semester} ${session?.year}`}
                    value={session?.scheduleID}
                  >{`CS${session?.courseID}-${session?.semester}-${session?.year}`}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            {message ? (
              <div
                className="alert alert-danger w-100 text-center"
                role="alert"
              >
                {message}
              </div>
            ) : null}
            <button
              type="button"
              className="btn btn-danger"
              onClick={onConfirm}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RosterModal;
