import React from 'react';
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Io from 'react-icons/io';

export const SidebarData = [
    {
        title: 'Dashboard',
        path: 'home',
        icon: <Ai.AiFillHome />,
        cName: 'nav-text navT'
    },
    {
        title: 'Profile',
        path: 'profile',
        icon: <Io.IoIosContact />,
        cName: 'nav-text navT'
    },
    {
        title: 'Weekly Sessions',
        path: 'sessions',
        icon: <Io.IoMdCalendar />,
        cName: 'nav-text navT'
    },
    {
        title: 'Manage Sessions',
        path: 'newsessions',
        icon: <Io.IoIosApps />,
        cName: 'nav-text navT'
    },
    {
        title: 'Attendance',
        path: 'attendance',
        icon: <Io.IoIosPeople />,
        cName: 'nav-text navT'
    },
    {
        title: 'SAM Materials',
        path: 'materials',
        icon: <Io.IoMdFiling />,
        cName: 'nav-text navT'
    },
    {
        title: 'SAM Says',
        path: 'samsays',
        icon: <Io.IoIosQuote />,
        cName: 'nav-text navT'
    },
    {
        title: 'Ask SAMs',
        path: 'asksams',
        icon: <Io.IoMdHelpCircle />,
        cName: 'nav-text navT'
    },
    {
        title: 'Practice Problems',
        path: 'practice',
        icon: <Io.IoMdCode />,
        cName: 'nav-text navT'
    }
];
