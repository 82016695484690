import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router";
import { Context, Fields } from "./App";
import AdminDashboard from "./AdminDashboard";
import homeLogo from "../assets/homeicon.svg";
import "../Styles/Nav.css";
const courseList = [2311, 2321, 3331, 3411];
function Nav({ logoutFunction }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useContext(Context);
  const goHome = () => {
    if (location.pathname === "/") {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  };
  const scrollTo = (elementID) => {
    let element = document.getElementById(`${elementID}`);
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <header className="header-style1-1 px-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-3 col-sm-4">
            <img
              id="homeButtonNavLink"
              src={homeLogo}
              onClick={goHome}
              draggable={false}
              alt="home"
            />
          </div>
          <div className="col-lg-9 col-md-9 col-sm-8">
            <div className="d-lg-flex justify-content-end">
              <div className="menu-holder">
                <div className="mobile-menu"></div>
                <div className="main-menu navigation">
                  <nav>
                    <ul>
                      <li><a>Courses</a>
                        <ul className="sub-menu">
                          <li><Link to={`courses/2311`} draggable={false}>
                            CS2311
                          </Link></li>
                          <li><Link to={`courses/2321`} draggable={false}>
                            CS2321
                          </Link></li>
                          <li><Link to={`courses/3331`} draggable={false}>
                            CS3331
                          </Link></li>
                          <li><Link to={`courses/3411`} draggable={false}>
                            CS3411
                          </Link></li>
                        </ul>
                      </li>
                      {userInfo.Signin ? (
                        <li><a>Account</a>
                          <ul className="sub-menu">
                            <li>{userInfo.Roles.Student ? (
                              <Link to={`/account`} draggable={false}>
                                Dashboard
                              </Link>
                            ) : null}</li>
                            <li>{userInfo.Roles.SAM ? (
                              <Link to={"/sam"} draggable={false}>
                                SAM Dash
                              </Link>
                            ) : null}</li>
                            <li>{userInfo.Roles.Advisor ? (
                              <Link to={"/advisor"} draggable={false}>
                                Advisor Dash
                              </Link>
                            ) : null}</li>
                            <li>{userInfo.Roles.Admin ? (
                              <Link to={"/admin"} draggable={false}>
                                Admin Dash
                              </Link>
                            ) : null}</li>
                            <li><Link
                              to={"/"}
                              draggable={false}
                              state={{ logout: true }}
                              onClick={logoutFunction}
                             
                            >
                              Log Out
                            </Link></li>
                          </ul>
                        </li>
                      ) : (
                        <li><Link to={`/login?redirect=${encodeURIComponent(location.pathname)}`} draggable={false}>
                          Login
                        </Link></li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Nav;
