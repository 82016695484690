import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "../components/Dashboard/Navbar.css";
import AdminNavbar from "../components/DashboardAdmin/Navbar";
import { Navigate, useLocation, Outlet, useNavigate } from "react-router";
import { Context } from "./App";

const AdminDashboard = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useContext(Context);
  const [data, setData] = useState("");
  const [page, setPage] = useState("");
  const [sams, setSams] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [courseSessions, setCourseSessions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const navigate = useNavigate();
  //To view all the data

  useEffect(() => {
    if(!userInfo.Signin){
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
    }else if(!userInfo.Roles.Admin){
      navigate('/');
    }else{
      fetchData();
    }
  }, []);

  const fetchData = () => {
    if (userInfo.Signin) {
      axios
        .get("https://api.sam.cs.mtu.edu/api/admin/getData", {
          withCredentials: true,
        })
        .then((response) => {
          setData(response?.data);
          setSams(response?.data?.sams);
          setAdvisors(response?.data?.advisors);
          setCourseSessions(response?.data?.courseSessions);
          setCourses(response?.data?.courses);
          setSemesters(response?.data?.semesters);
        });
    }
  };

  return !userInfo.Signin ? (
    <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />
  ) : (
    <div className="App">
      <AdminNavbar setPage={setPage} />
      <div className="dash-content">
        <div className="px-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
