import React, { useState, useEffect } from "react";
import axios from "axios";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";

const ManageCourseSessions = () => {
  const [type, setType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [courseID, setCourseID] = useState("");
  const [scheduleID, setScheduleID] = useState("");
  const [sectionInfo, setSectionInfo] = useState("");
  const [semester, setSemester] = useState("");
  const [year, setYear] = useState("");
  const [session, setSession] = useState("");
  const [courses, setCourses] = useState([]);
  const [validSemesters, setValidSemesters] = useState([]);
  const years = Array.from({ length: 8 }, (_, index) => 2023 + index); // Generate years 2024 to 2030

  // Search and Pagination States
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Number of rows per page

  const refresh = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/admin/getCourseSessions")
      .then((response) => {
        setCourses(response?.data?.courses);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getValidSemesters = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/admin/getSemesters")
      .then((response) => {
        console.log(response?.data?.semesters);
        setValidSemesters(response?.data?.semesters);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    refresh();
    getValidSemesters();
  }, []);
  useEffect(() => {
    if (message != null) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Filter courses based on the search term
  const filteredCourses = courses.filter(
    (course) =>
      course?.courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course?.sectionInfo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course?.courseID.toString().includes(searchTerm)
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredCourses.length / rowsPerPage);
  const paginatedCourses = filteredCourses.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showAdd = () => {
    setCourseID("2311");
    setSectionInfo("");
    setScheduleID("");
    setSemester("Fall");
    setYear("2023");
    setSession(`${validSemesters[0]?.semester} ${validSemesters[0]?.year}`);
    setType("add");
    setShowModal(true);
  };

  const addCourse = () => {
    if (courseID && sectionInfo && semester && year) {
      axios
        .post(`https://api.sam.cs.mtu.edu/api/admin/addCourseSession`, {
          courseID,
          sectionInfo,
          semester,
          year,
        })
        .then(() => {
          setError(false);
          setMessage("Course Added Successfully!");
          refresh();
        })
        .catch(() => {
          setError(true);
          setMessage("Something went wrong! Please try again!");
        });
    } else {
      setError(true);
      setMessage("Please fill all fields!");
    }
  };

  const showEdit = (course) => {
    setCourseID(course.courseID);
    setScheduleID(course.scheduleID);
    setSectionInfo(course.sectionInfo);
    setSemester(course.semester);
    setYear(course.year);
    setSession(`${course.semester} ${course.year}`);

    setType("edit");
    setShowModal(true);
  };

  const editCourse = () => {
    if (scheduleID && sectionInfo && semester && year && courseID) {
      axios
        .patch(`https://api.sam.cs.mtu.edu/api/admin/updateCourseSession`, {
          scheduleID,
          sectionInfo,
          semester,
          year,
          courseID,
        })
        .then(() => {
          setError(false);
          setMessage("Course Edited Successfully!");
          refresh();
        })
        .catch(() => {
          setError(true);
          setMessage("Something went wrong! Please try again!");
        });
    } else {
      setError(true);
      setMessage("Please fill all fields!");
    }
  };

  const showDelete = (courseID) => {
    setScheduleID(courseID);
    setShowDeleteModal(true);
  };

  const deleteCourse = () => {
    axios
      .patch(`https://api.sam.cs.mtu.edu/api/admin/deleteCourseSession`, {
        scheduleID,
      })
      .then(() => {
        setError(false);
        setMessage("Course has been deleted.");
        refresh();
      })
      .catch(() => {
        setError(true);
        setMessage("Delete failed!");
      });
  };
  return (
    <>
      <div className="container col-12 col-lg-8 col-md-8">
        <h4 className="text-left text-dark mb-5 mt-5 col-md-6">
          Course Sessions Table
        </h4>

        <button className="mySmBtn mb-3" onClick={() => showAdd()}>
          Add Course
        </button>

        {/* Search Input */}
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search Courses..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {message && (
          <p
            className={`alert ${!error ? "alert-success" : "alert-danger"} mt-3`}
          >
            {message}
          </p>
        )}

        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Course ID</th>
                <th>Section Info</th>
                <th>Semester</th>
                <th>Year</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCourses.map((course, idx) => (
                <tr key={idx}>
                  <td>
                    <code>{(currentPage - 1) * rowsPerPage + idx + 1}</code>
                  </td>
                  <td>{course.courseID}</td>
                  <td>{course.sectionInfo}</td>
                  <td>{course.semester}</td>
                  <td>{course.year}</td>
                  <td>
                    <i
                      className="bi bi-pencil-square text-success"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={() => showEdit(course)}
                    ></i>
                    <i
                      className="bi bi-trash text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => showDelete(course.scheduleID)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <div>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={`btn btn-sm mx-1 ${currentPage === i + 1 ? "mySmBtn" : "mySecSmBtn"}`}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={showDeleteModal}
        title="Confirm Deletion"
        message="Are you sure you want to delete this course?"
        onConfirm={() => {
          deleteCourse();
          setShowDeleteModal(false);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />

      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">
                  {type === "add" ? "Add New  Session" : "Edit Course Session"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <label>Course ID</label>
                  <select
                    onChange={(e) => setCourseID(e.target.value)}
                    defaultValue={courseID}
                    className="form-control"
                  >
                    <option value="2311">CS2311</option>
                    <option value="2321">CS2321</option>
                    <option value="3331">CS3331</option>
                    <option value="3411">CS3411</option>
                  </select>
                </div>
                <div style={{ display: "none" }} className="form-group mb-3">
                  <label>Schedule ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={scheduleID}
                    disabled={true}
                    onChange={(e) => setScheduleID(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Section Info</label>
                  <textarea
                    className="form-control"
                    value={sectionInfo}
                    onChange={(e) => setSectionInfo(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group mb-3">
                  <label>Session</label>
                  <select
                    className="form-control"
                    value={`${semester} ${year}`}
                    onChange={(e) => setSession(e.target.value)}
                  >
                    {validSemesters.map((session) => (
                      <option key={`${session?.semester} ${session?.year}`} value={`${session?.semester} ${session?.year}`}>{`${session?.semester} ${session?.year}`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    type === "edit" ? editCourse() : addCourse();
                    setShowModal(false);
                  }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageCourseSessions;
