import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import * as Ai from "react-icons/ai";
import ConfirmationModal from "../SAMDashComponents/ConfirmationModal";
import AdvisorSessions from "./AdvisorSessions";

const ManageAdvisors = () => {
    const [type, setType] = useState("");
    const [showSessionsModal, setShowSessionsModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [courses, setCourses] = useState([]);
    const [advisors, setAdvisors] = useState([]);
    const [courseSessions, setCourseSessions] = useState([]);
    // Search and Pagination States
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Number of rows per page
    
    const refresh = ()=>{
      let p1 = axios.get("https://api.sam.cs.mtu.edu/api/admin/getAdvisors");
      let p2 = axios.get("https://api.sam.cs.mtu.edu/api/admin/getCourseSessions");
      Promise.all([p1,p2])
      .then((response)=>{
        setAdvisors(response[0]?.data?.advisors);
        setCourseSessions(response[1]?.data?.courses);
      })
      .catch((error)=>{
        console.log(error);
      })
    }

    useEffect(()=>{
      refresh();
    },[]);
    useEffect(() => {
        if (message != null) {
          const timer = setTimeout(() => {
            setMessage(null);
          }, 3000);
          return () => clearTimeout(timer);
        }
    }, [message]);

    // Filter SAMs based on the search term
    const filteredAdvisors = advisors.filter((advisor) =>
        advisor.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        advisor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        advisor.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calculate pagination
    const totalPages = Math.ceil(filteredAdvisors.length / rowsPerPage);
    const paginatedAdvisors = filteredAdvisors.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showAdd = () => {
        setUsername("");
        setName("");
        setEmail("");

        setType("add");
        setShowModal(true);
    };

    const addAdvisor = () => {
        try {
            if (username && name && email) {
                axios.post(
                    `https://api.sam.cs.mtu.edu/api/admin/addAdvisor`,
                    {
                        username: username,
                        email: email,
                        fullName: name,
                    },
                )
                .then((response) => {
                    setError(false);
                    setMessage("Advisor Added Successfully!");
                    refresh();
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
            } else {
                setError(true);
                setMessage("Please fill all fields!");
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setMessage("Something went wrong! Please try again!");
        }
    };

    const showEdit = (username, name, email) => {
        setUsername(username);
        setName(name);
        setEmail(email);

        setType("edit");
        setShowModal(true);
    };

    const editAdvisor = () => {
        try {
            if (username && name && email) {
                axios.patch(
                    `https://api.sam.cs.mtu.edu/api/admin/updateAdvisor`,
                    {
                        username: username,
                        fullName: name,
                        email: email
                    },
                )
                .then((response) => {
                    setError(false);
                    setMessage("Advisor Edited Successfully!");
                    refresh();
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                    setMessage("Something went wrong! Please try again!");
                });
            } else {
                setError(true);
                setMessage("Please fill all fields!");
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setMessage("Something went wrong! Please try again!");
        }
    };

    const showDelete = (username) => {
        setUsername(username);
        setShowDeleteModal(true);
    };

    const deleteAdvisor = () => {
        axios.patch(
            `https://api.sam.cs.mtu.edu/api/admin/deleteAdvisor`,
            {username: username},
            { withCredentials: true }
        )
        .then((response) => {
            setError(false);
            setMessage("Advisor has been deleted.");
            refresh();
        })
        .catch((error) => {
            console.log(error);
            setError(true);
            setMessage("Delete failed!");
        });
    }

    const manageSessions = (username, courses) => {
        setUsername(username);
        setCourses(courses);

        setShowSessionsModal(true);
    }

    return (
        <>
            <div className="container col-12 col-lg-8 col-md-8">
                <h4 className="text-left text-dark mb-5 mt-5 col-md-6">
                    Advisors Table
                </h4>

                <button
                    className="mySmBtn justify-content-end mb-2"
                    onClick={() => showAdd()}
                >
                    Add Advisor
                </button>

                {/* Search Input */}
                <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search Advisors..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {message && (
                    <p className={`alert ${!error ? "alert-success" : "alert-danger"} mt-3`}>{message}</p>
                )}

                <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Username</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Course Schedules</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedAdvisors?.map((data, idx) => (
                                <tr key={idx}>
                                    <td><code>{(currentPage - 1) * rowsPerPage + idx + 1}</code></td>
                                    <td>{data?.username}</td>
                                    <td>{data?.name}</td>
                                    <td>{data?.email}</td>
                                    <td>
                                        <button className="btn btn-sm btn-warning" onClick={() => manageSessions(data?.username, data?.courses)}>
                                            Manage Sessions
                                        </button>
                                    </td>
                                    <td>
                                        <i
                                            className="bi bi-pencil-square text-success"
                                            style={{
                                                cursor: "pointer",
                                                marginRight: "10px",
                                            }}
                                            onClick={() =>
                                                showEdit(
                                                    data?.username,
                                                    data?.name,
                                                    data?.email
                                                )
                                            }
                                        ></i>
                                        <i
                                            className="bi bi-trash text-danger"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                showDelete(
                                                    data?.username
                                                )
                                            }
                                        ></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span>Page {currentPage} of {totalPages}</span>
                    <div>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i}
                                className={`btn btn-sm mx-1 ${currentPage === i + 1 ? "mySmBtn" : "mySecSmBtn"}`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <ConfirmationModal
                show={showDeleteModal}
                title="Confirm Deletion"
                message="Are you sure you want to delete this Advisor?"
                onConfirm={() => {
                    deleteAdvisor();
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />

            {/* Manage Sessions Modal */}
            {showSessionsModal &&
                <AdvisorSessions
                    username={username}
                    courses={courses}
                    courseSessions={courseSessions}
                    setShowModal={setShowSessionsModal}
                    setError={setError}
                    setMessage={setMessage}
                    refresh={refresh}
                />
            }

            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-dark">
                                    {type === "add" ? "Add New Advisor" : "Edit Advisor"}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="form-group mb-3">
                                        <label className="form-label">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Username"
                                            value={username}
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter Email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        type == "edit" ? editAdvisor() : addAdvisor();
                                        setShowModal(false);
                                    }}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ManageAdvisors;
