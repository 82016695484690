import React from "react";
import ReactDOM from "react-dom/client";
import App from "./routes/App";
import Homepage from "./routes/Homepage";
import Course from "./routes/Course";
import Account from "./routes/Account";
import Login from "./routes/Login";
import AdminDashboard from "./routes/AdminDashboard";
import SamDashboard from "./routes/SamDashboard";
import AdvisorDashboard from "./routes/AdvisorDashboard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider, createHashRouter, Navigate} from "react-router";
import { StrictMode } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;
import "./Styles/Index.css";
import { AppStateProvider } from "./store/AppStateContext";
import UpdateProfile from "./components/SAMDashComponents/UpdateProfile.jsx";
import SamCourseSchedule from "./components/SAMDashComponents/SamCourseSchedule.jsx";
import SessionUpdaters from "./components/SAMDashComponents/SessionUpdaters.jsx";
import UpdateAttendance from "./components/SAMDashComponents/UpdateAttendance.jsx";
import FileSharing from "./components/SAMDashComponents/FileSharing.jsx";
import UpdateSamSays from "./components/SAMDashComponents/UpdateSamSays.jsx";
import ViewAskSAM from "./components/SAMDashComponents/ViewAskSAM.jsx";
import PracticeProblemsSam from "./components/SAMDashComponents/PracticeProblemsSam.jsx";
import SamHome from "./components/SAMDashComponents/SamHome.jsx";
import ManageSams from "./components/AdminDashboardComponents/ManageSam.jsx";
import ManageAdvisors from "./components/AdminDashboardComponents/ManageAdvisors.jsx";
import ManageSemesters from "./components/AdminDashboardComponents/ManageSemesters.jsx";
import ManageCourseSessions from "./components/AdminDashboardComponents/ManageCourseSessions.jsx";
import ManageCourses from "./components/AdminDashboardComponents/ManageCourses.jsx";
import AdminHome from "./components/AdminDashboardComponents/AdminHome.jsx";
import ManageStudents from "./components/AdminDashboardComponents/ManageStudents.jsx";
import AdvisorHome from "./components/AdvisorDashboardComponents/AdvisorHome.jsx";
import AdminRoster from "./components/AdminDashboardComponents/UploadRoster.jsx";
import AdvisorRoster from "./components/AdvisorDashboardComponents/UploadRoster.jsx";
import SpecialSessions from "./components/SAMDashComponents/SpecialSessions.jsx";
const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
      {
        path: "/courses/:courseID",
        element: <Course />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/sam",
        element: <SamDashboard />,
          children:[
            {path: "*", element: <Navigate to="/sam" replace/>},
            {path: "home/:courseID", element: <SamHome />},
            {path: "profile/:courseID", element: <UpdateProfile />},
            {path: "sessions/:courseID", element: <SamCourseSchedule />},
            {path: "newsessions/:courseID", element: <SessionUpdaters /> },
            {path: "attendance/:courseID", element: <UpdateAttendance />},
            {path: "materials/:courseID", element: <FileSharing />},
            {path: "samsays/:courseID", element: <UpdateSamSays />},
            {path: "asksams/:courseID", element: <ViewAskSAM />},
            {path: "practice/:courseID", element: <PracticeProblemsSam />}
          ]
      },
      {
        path: "/advisor",
        element: <AdvisorDashboard />,
        children:[
          {path: "*", element: <Navigate to="/advisor" replace/>},
          {path: "home/:sessionID", element: <AdvisorHome />},
          {path: "roster/:sessionID", element: <AdvisorRoster />}
        ]
      },
      {
        path: "/admin",
        element: <AdminDashboard />,
          children:[
            {path: "*", element: <Navigate to="/admin" replace />},
            {path: "", element: <AdminHome />},
            {path: "students", element: <ManageStudents />},
            {path: "sams", element: <ManageSams />},
            {path: "advisors", element: <ManageAdvisors />},
            {path: "semesters", element: <ManageSemesters />},
            {path: "sessions", element: <ManageCourseSessions />},
            {path: "courses", element: <ManageCourses />},
            {path: "roster", element: <AdminRoster />}
          ]
      },
    ],
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
    <AppStateProvider>
      <GoogleOAuthProvider clientId="463860053272-quaab5kcs3hm58kreqf0po2ok602g083.apps.googleusercontent.com">
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
      </GoogleOAuthProvider>
    </AppStateProvider>
  </StrictMode>,
);
