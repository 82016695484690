import React, {useState, useEffect} from "react";
import axios from "axios";
import UserProfile from "../../userData/UserProfile";
import { useAppState } from "../../store/AppStateContext";

const AdvisorHome = () => {
  
  const [data, setData] = useState('');
  const { selectedScheduleID, setSelectedScheduleID } = useAppState();
  
  useEffect(() => {
    if(selectedScheduleID){
    axios.get(`https://api.sam.cs.mtu.edu/api/advisor/counts/${selectedScheduleID}`)
      .then((response) => {
        console.log(data);
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }, [selectedScheduleID]);

  return (
    <div className="">
        <div className="row m-3">
            <div className="col-12 mb-3">
                <h5 className="mb-0 mt-5 text-dark text-left">Welcome back, {UserProfile.getName()}</h5>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
                <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                    <div className="card-body text-center">
                        <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                            <h5 className="mb-0 text-dark text-center">{data?.studentsInCourse || 0}</h5>
                            <p className="mb-3 text-dark text-center fw-bold">Total Students</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
                <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                    <div className="card-body text-center">
                        <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                            <h5 className="mb-0 text-dark text-center">{data?.sessionsRan || 0}</h5>
                            <p className="mb-3 text-dark text-center fw-bold">Total Sessions Ran</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
                <div className="card radius-15 mt-3" style={{ background: "#ffcc00" }}>
                    <div className="card-body text-center">
                        <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                            <h5 className="mb-0 text-dark text-center">{data?.totalStudentsAttended || 0}</h5>
                            <p className="mb-3 text-dark text-center fw-bold">Total Students Attended</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
              <div
                className="card radius-15 mt-3"
                style={{ background: "#ffcc00" }}
              >
                <div className="card-body text-center">
                  <div className="d-flex flex-column align-items-center justify-content-center p-4 radius-15">
                    <p className="mb-0 text-dark text-center fw-bold">{data?.nextSession || "-"}</p>
                    <p className="mb-3 text-dark text-center fw-bold">
                      {data?.nextSession ? "Next Session" : "No Upcoming Session"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  );
};

export default AdvisorHome;
